<script>
import { eventBus } from "@/main";
import { api } from "@/api";
import {
  CONTACT_TYPE,
  CONTACT_TYPE_ARRAY,
} from "@/components/Common/constants";
import EmailInput from "@/components/Inputs/EmailInput.vue";
import AgreementText from "@/components/Auth/AgreementText.vue";

export default {
  name: "PostListingCompanyDialog",
  components: { AgreementText, EmailInput },
  props: {
    value: Boolean,
  },
  data() {
    return {
      isLoading: false,
      isFormValid: true,
      formData: {
        name_company: "",
        type_business: null,
        website: "",
        contact_name: "",
        contact_surname: "",
        contact_email: "",
        contact_number_phone: "",
        contact_wa: "",
        contact_tg: "",
        cookies_agreement: false,
        service_agreement: false,
      },
      errors: null,
      utmParams: null,
      referer: null,
      businessTypes: [],
      contacts: [],
    };
  },

  async created() {
    this.isLoading = true;
    this.errors = Object.keys(this.formData).reduce((acc, fieldName) => {
      acc[fieldName] = { hasError: false };
      return acc;
    }, {});

    try {
      const { data } = await api.get(`/properties/business_types/`);
      this.businessTypes = data;
    } catch (error) {
      eventBus.$emit("showSnackbarWithAction", {
        text: error.response.data.detail,
        color: "error",
        actionText: this.$t("reload"),
        actionHandler: () => {
          location.reload();
        },
        timeout: 9000,
      });
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    modelValue: function (newValue) {
      if (newValue) {
        this.resetFormData();
      }
    },
  },

  computed: {
    CONTACT_TYPE() {
      return CONTACT_TYPE;
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    rules() {
      return {
        required: (value) => !!value || this.$t("errors.field_is_required"),
        rulesRequired: (value) =>
          !!value || this.$t("errors.rules_agreement_text"),
      };
    },

    partnerPortalUrl() {
      return `${process.env.VUE_APP_PARTNER_PORTAL_URL}/${this.$i18n.locale}/auth`;
    },
  },

  methods: {
    CONTACT_TYPE_ARRAY() {
      return CONTACT_TYPE_ARRAY;
    },
    closeModal() {
      this.resetFormData();
      this.modelValue = false;
    },
    addUTMparamsToForm() {
      for (const [key, value] of Object.entries(this.utmParams)) {
        this.formData[key] = value;
      }
    },
    addHTTPrefererToForm() {
      this.formData["referer"] = this.referer;
    },
    async onSubmit() {
      this.$refs.form.validate();
      if (
        String(this.formData.contact_number_phone).trim() === "" ||
        this.formData.contact_number_phone === null
      ) {
        this.errors.contact_number_phone.hasError = true;

        this.isFormValid = false;
      }

      if (this.isFormValid) {
        try {
          this.isLoading = true;
          // this.addUTMparamsToForm();
          // this.addHTTPrefererToForm();
          await api.post(`partners/save_lead_to_db/`, this.formData);

          eventBus.$emit("showSnackbar", {
            text: this.$t("partner.success_submit"),
            color: "success",
          });
          this.closeModal();
          this.resetFormData();
        } catch (error) {
          const responseErrs =
            error.request.data || JSON.parse(error.request.response);
          Object.keys(responseErrs).forEach((field) => {
            eventBus.$emit("showSnackbar", {
              text: responseErrs[field],
              color: "error",
            });
            if (field in this.errors) {
              this.errors[field].hasError = true;
            }
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    resetFormData() {
      this.formData.name_company = "";
      this.formData.type_business = null;
      this.formData.website = "";
      this.formData.contact_name = "";
      this.formData.contact_surname = "";
      this.formData.contact_email = "";
      this.formData.contact_number_phone = "";
      this.formData.contact_wa = "";
      this.formData.contact_tg = "";

      this.formData.cookies_agreement = false;
      this.formData.service_agreement = false;

      this.contacts = [
        {
          type: CONTACT_TYPE.EMAIL,
          label: this.$t("email"),
          value: "",
        },
        {
          type: CONTACT_TYPE.PHONE,
          label: this.$t("phone_number"),
          value: "",
        },
      ];

      if (this.$store.getters.isAuthenticated) {
        this.formData.contact_name = this.$store.getters.user.first_name;
        this.formData.contact_surname = this.$store.getters.user.last_name;
      }
    },
    getStateKeyByContactType(type) {
      switch (type) {
        case CONTACT_TYPE.EMAIL:
          return "contact_email";

        case CONTACT_TYPE.PHONE:
          return "contact_number_phone";

        case CONTACT_TYPE.TELEGRAM:
          return "contact_tg";

        case CONTACT_TYPE.WHATS_APP:
          return "contact_wa";

        default:
          return "";
      }
    },
    resetErrors(field) {
      this.errors[field].hasError = false;
    },
    getContactFieldRules(fieldType) {
      if (fieldType === CONTACT_TYPE.PHONE) {
        return [this.rules.required];
      }

      return [];
    },
    checkIsContactTypeAlreadyExist(type) {
      return this.contacts.map((contact) => contact.type).includes(type);
    },
    checkContactFieldRemovable(type) {
      return type === CONTACT_TYPE.PHONE || type === CONTACT_TYPE.EMAIL
        ? ""
        : "mdi-close";
    },
    onAddContactClick(data) {
      this.contacts.push({
        type: data.type,
        label: data.text,
        value: "",
      });

      if (data.type === CONTACT_TYPE.WHATS_APP) {
        this.formData.contact_wa = this.formData.contact_number_phone;
      }

      if (data.type === CONTACT_TYPE.TELEGRAM) {
        this.formData.contact_tg = this.formData.contact_number_phone;
      }
    },
    onRemoveContactClick(data) {
      this.contacts = this.contacts.filter((item) => {
        return item.label !== data.label;
      });
    },
    onEnterToProfileClick() {
      this.closeModal();
      const url = new URL(this.partnerPortalUrl);
      window.open(url, "_blank")?.focus();
    },
  },
};
</script>

<template>
  <v-dialog v-model="modelValue" v-if="modelValue" max-width="540">
    <v-card class="partners-form-wrapper">
      <v-btn class="close-button" icon color="#b4b4b4" @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="partners-form">
        <div class="d-flex align-center flex-column mb-4">
          <p class="partners-form__title">{{ $t("partner.become_partner") }}</p>
        </div>

        <div class="partners-form-info-text">
          <p class="mb-0">
            {{ $t("map.post_listing_modal.additional_info_text_company") }}
          </p>
        </div>

        <v-form ref="form" v-model="isFormValid" @submit.prevent="onSubmit">
          <div class="mb-6">
            <div class="form-section-label">
              {{ $t("partner.info_about_company") }}
            </div>

            <v-text-field
              v-model.trim="formData.name_company"
              :rules="[rules.required]"
              :label="$t('partner.company_name')"
              outlined
              class="mb-4"
              hide-details
            ></v-text-field>
            <v-select
              v-model="formData.type_business"
              :rules="[rules.required]"
              :label="$t('partner.bussines_type')"
              :items="businessTypes"
              item-text="name"
              item-value="id"
              outlined
              clearable
              hide-details
              class="mb-4"
            ></v-select>
            <v-text-field
              v-model.trim="formData.website"
              :label="$t('partner.web-site')"
              outlined
              class="mb-4"
              hide-details
            ></v-text-field>
          </div>

          <div class="mb-6">
            <div class="form-section-label">
              {{ $t("partner.contact_person") }}
            </div>

            <v-row dense>
              <v-col sm="6" class="mb-2">
                <v-text-field
                  v-model.trim="formData.contact_name"
                  :rules="[rules.required]"
                  :label="$t('first_name')"
                  outlined
                  hide-details
                  class="mr-1"
                ></v-text-field>
              </v-col>
              <v-col sm="6" class="mb-2">
                <v-text-field
                  v-model.trim="formData.contact_surname"
                  :rules="[rules.required]"
                  :label="$t('last_name')"
                  outlined
                  hide-details
                  class="ml-1"
                ></v-text-field>
              </v-col>

              <template v-for="contact in contacts">
                <v-col
                  :key="contact.type"
                  v-if="contact.type === CONTACT_TYPE.PHONE"
                  sm="12"
                  cols="12"
                  class="mb-2"
                >
                  <maz-phone-number-input
                    class="partners-form-phone-number-input"
                    v-model="formData[getStateKeyByContactType(contact.type)]"
                    show-code-on-list
                    :translations="{
                      countrySelectorLabel: $t('propertyView.country_code'),
                      countrySelectorError: $t('propertyView.choose_country'),
                      phoneNumberLabel: $t('propertyView.phone_number'),
                      example: $t('propertyView.example'),
                    }"
                    default-country-code="TR"
                    :preferred-countries="['TR', 'RU', 'BY', 'KZ', 'UA']"
                    no-search
                    :error="
                      errors[getStateKeyByContactType(contact.type)].hasError
                    "
                    @update="
                      resetErrors(getStateKeyByContactType(contact.type))
                    "
                  ></maz-phone-number-input>
                </v-col>
                <v-col
                  :key="contact.type"
                  v-else-if="contact.type === CONTACT_TYPE.EMAIL"
                  sm="12"
                  cols="12"
                  md="12"
                  class="mb-2"
                >
                  <EmailInput
                    v-model="formData[getStateKeyByContactType(contact.type)]"
                    :error="
                      errors[getStateKeyByContactType(contact.type)].hasError
                    "
                    @change="
                      resetErrors(getStateKeyByContactType(contact.type))
                    "
                  />
                </v-col>
                <v-col
                  :key="contact.type"
                  v-else
                  cols="12"
                  sm="12"
                  md="6"
                  class="mb-2"
                >
                  <v-text-field
                    v-model.trim="
                      formData[getStateKeyByContactType(contact.type)]
                    "
                    :rules="getContactFieldRules(contact.type)"
                    :label="contact.label"
                    outlined
                    :append-inner-icon="
                      checkContactFieldRemovable(contact.type)
                    "
                    @click:append-inner.stop="onRemoveContactClick(contact)"
                    hide-details
                    :error="
                      errors[getStateKeyByContactType(contact.type)].hasError
                    "
                    @change="
                      resetErrors(getStateKeyByContactType(contact.type))
                    "
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :disabled="contacts.length === 4"
                  text
                  density="comfortable"
                  color="#0062FF"
                  class="add-item-button"
                >
                  <span class="add-item-button__text">{{
                    $t("partner.add_contact")
                  }}</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="contact in CONTACT_TYPE_ARRAY()"
                  :key="contact.type"
                  :value="contact.type"
                  :disabled="checkIsContactTypeAlreadyExist(contact.type)"
                  @click="onAddContactClick(contact)"
                >
                  <div class="d-flex align-center">
                    <v-icon color="#D7D7D7" class="mr-2">{{
                      contact.icon
                    }}</v-icon>
                    <v-list-item-title>{{
                      $t(contact.text)
                    }}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <div class="agreements-section">
            <v-checkbox
              v-model="formData.service_agreement"
              class="agreements-section__checkbox"
              density="compact"
              color="#0062FF"
              hide-details
            >
              <template v-slot:label>
                <span class="ml-2">{{
                  $t("profile.agree_service_messages")
                }}</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="formData.cookies_agreement"
              :rules="[rules.rulesRequired]"
              required
              class="agreements-section__checkbox"
              density="compact"
              color="#0062FF"
            >
              <template v-slot:label>
                <AgreementText class="ml-2" />
              </template>
            </v-checkbox>
          </div>

          <div class="action-buttons">
            <v-btn
              class="action-button"
              :loading="isLoading"
              elevation="0"
              block
              large
              color="#FECC00"
              @click="onSubmit"
            >
              <span class="action-button__text">{{
                $t("partner.submit_application")
              }}</span>
            </v-btn>
          </div>

          <div class="partners-form__account-exist">
            <span>{{ $t("partner.already_have_partner_account") }}</span>
            <v-btn
              class="partners-form__account-exist-button"
              text
              color="#0062FF"
              @click="onEnterToProfileClick"
            >
              <span class="font-weight-bold">{{ $t("partner.log_in") }}</span>
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.close-button {
  position: absolute;
  top: 14px;
  right: 14px;
}

.partners {
  &-form-wrapper {
    width: 100%;
    padding: 40px;

    @media (max-width: 768px) {
      padding: 40px 20px 20px;
    }

    .form-section-label {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: #8c8c8c;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    .add-item-button {
      &__text {
        color: #0062ff;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-transform: initial;
      }
    }

    .partners-form {
      &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        color: #212121;
        margin-bottom: 8px;
        text-align: center;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      &__subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #616161;
      }

      &__account-exist {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 16px;
        line-height: 24px;
        color: #616161;
        margin-top: 24px;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        &-button {
          font-size: 16px;
          font-weight: 500 !important;
          text-transform: initial;
          letter-spacing: 0;
        }
      }

      &-info-text {
        background-color: rgba(254, 204, 0, 0.1);
        padding: 8px 16px;
        border-radius: 4px;
        margin-bottom: 32px;

        p {
          font-size: 14px;
          color: #3d3d3d;
        }
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;

      .action-button {
        height: 48px;
      }

      .action-button:disabled {
        color: rgb(255 231 134) !important;
      }

      .action-button__text {
        color: #3d3d3d;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding: 0 24px;
      }
    }
  }
}
:deep(.partners-form-phone-number-input) {
  .maz-input {
    height: 56px;
  }
  .maz-phone-number-input__country-flag {
    bottom: 0.849rem;
  }
  &.maz-phone-number-input .country-selector {
    width: 135px;
    min-width: 135px;
    max-width: 135px;
    flex: 0 0 135px;
  }
}
</style>
