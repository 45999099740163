<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="16" height="3" fill="#8C8C8C" />
    <rect x="6" y="10" width="12" height="3" fill="#B4B4B4" />
    <rect x="9" y="16" width="6" height="3" fill="#D7D7D7" />
  </svg>
</template>
<script>
export default {
  name: "SortDescIcon",
};
</script>
<style scoped></style>
