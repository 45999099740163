import { render, staticRenderFns } from "./LinkIcon.vue?vue&type=template&id=53cbd95c&scoped=true&"
import script from "./LinkIcon.vue?vue&type=script&lang=js&"
export * from "./LinkIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53cbd95c",
  null
  
)

export default component.exports