import { render, staticRenderFns } from "./SortDescIcon.vue?vue&type=template&id=f4fd1b1e&scoped=true&"
import script from "./SortDescIcon.vue?vue&type=script&lang=js&"
export * from "./SortDescIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4fd1b1e",
  null
  
)

export default component.exports