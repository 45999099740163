<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7424 4.04824C19.2184 3.56672 17.1006 2.03581 12.3835 2.01501C12.3835 2.01501 6.82069 1.68097 4.10894 4.15791C2.59942 5.6614 2.06839 7.86134 2.01238 10.5888C1.95637 13.3163 1.8839 18.4277 6.83177 19.8137H6.83652L6.83335 21.9285C6.83335 21.9285 6.80171 22.7847 7.36786 22.9593C8.05268 23.1711 8.45458 22.5203 9.10839 21.8188C9.46726 21.4337 9.96284 20.8681 10.3363 20.4357C13.7202 20.7193 16.3228 20.0711 16.6183 19.9753C17.3016 19.7547 21.1678 19.2612 21.7966 14.1504C22.4457 8.88236 21.4827 5.55016 19.7424 4.04824ZM20.3159 13.7726C19.7852 18.0401 16.6494 18.3083 16.0712 18.4929C15.8253 18.5717 13.5395 19.138 10.6651 18.9511C10.6651 18.9511 8.52326 21.5242 7.85426 22.1932C7.74983 22.2978 7.62704 22.3401 7.54507 22.3193C7.42988 22.2912 7.39824 22.1554 7.3995 21.9569C7.4014 21.6732 7.41786 18.4425 7.41786 18.4425C7.41532 18.4425 7.41786 18.4425 7.41786 18.4425C3.23234 17.2853 3.47633 12.935 3.5238 10.6569C3.57127 8.37878 4.00102 6.51257 5.27763 5.2574C7.57134 3.18856 12.2964 3.49771 12.2964 3.49771C16.2867 3.51504 18.1987 4.71159 18.6421 5.11244C20.1143 6.36761 20.8643 9.37113 20.3159 13.7713V13.7726Z"
      fill="#7360F2"
    />
    <path
      d="M14.3253 10.4701C14.2734 9.42825 13.7419 8.88107 12.731 8.82855"
      stroke="#7360F2"
      stroke-width="0.533554"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.694 10.927C15.7151 9.9564 15.4263 9.14547 14.8278 8.49419C14.2265 7.84061 13.3942 7.48073 12.3262 7.40289"
      stroke="#7360F2"
      stroke-width="0.533554"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.0953 11.473C17.0824 9.78869 16.577 8.46219 15.5791 7.49348C14.5812 6.52476 13.3402 6.03526 11.856 6.02496"
      stroke="#7360F2"
      stroke-width="0.533554"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.7837 14.0286C12.7837 14.0286 13.1587 14.0601 13.3606 13.8124L13.7543 13.3193C13.9442 13.0747 14.4024 12.9187 14.8512 13.1677C15.1874 13.3587 15.5139 13.5663 15.8294 13.7897C16.1271 14.0078 16.737 14.5146 16.7389 14.5146C17.0294 14.7588 17.0965 15.1174 16.8987 15.4956C16.8987 15.4978 16.8971 15.5015 16.8971 15.5034C16.6793 15.8795 16.4027 16.2186 16.0778 16.5081C16.074 16.51 16.074 16.5119 16.0705 16.5137C15.7882 16.7486 15.5109 16.8821 15.2385 16.9143C15.1984 16.9213 15.1577 16.9238 15.117 16.9218C14.9969 16.923 14.8774 16.9047 14.7632 16.8676L14.7543 16.8547C14.3347 16.7369 13.6341 16.4419 12.4673 15.8009C11.7921 15.4344 11.1492 15.0115 10.5457 14.5369C10.2432 14.2992 9.95464 14.0443 9.68146 13.7737L9.65234 13.7447L9.62323 13.7157L9.59411 13.6867C9.5843 13.6772 9.57481 13.6675 9.565 13.6577C9.29319 13.3857 9.03728 13.0983 8.79853 12.7971C8.322 12.1961 7.89738 11.5561 7.5292 10.8839C6.88552 9.72171 6.58931 9.02464 6.47096 8.60615L6.45798 8.59732C6.42088 8.48358 6.40261 8.36459 6.40387 8.24501C6.40165 8.20453 6.40409 8.16393 6.41115 8.124C6.44511 7.85319 6.5794 7.57682 6.814 7.29489C6.8159 7.29142 6.8178 7.29142 6.8197 7.28764C7.1103 6.9641 7.45087 6.68878 7.82858 6.47208C7.83047 6.47208 7.83427 6.47019 7.83649 6.47019C8.21624 6.27323 8.57637 6.34004 8.82132 6.62775C8.82321 6.62964 9.33113 7.2369 9.54918 7.53344C9.77362 7.84786 9.98211 8.17327 10.1739 8.50846C10.4239 8.955 10.2672 9.41225 10.0217 9.6007L9.52639 9.99273C9.27639 10.1938 9.3093 10.5672 9.3093 10.5672C9.3093 10.5672 10.0429 13.3319 12.7837 14.0286Z"
      fill="#7360F2"
    />
  </svg>
</template>

<script>
export default {
  name: "ViberOutlineIcon",
};
</script>

<style scoped></style>
