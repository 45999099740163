<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.0141 13V20.875C14.0453 21.2188 13.9516 21.4922 13.733 21.6953C13.5144 21.8984 13.2724 22 13.007 22C12.7416 22 12.4996 21.9062 12.281 21.7188L10.3138 19.7031C10.064 19.4531 9.95472 19.1719 9.98595 18.8594V13L4.22482 5.64062C4.03747 5.42188 3.96721 5.17188 4.01405 4.89062C4.06089 4.60938 4.18579 4.39062 4.38876 4.23438C4.59173 4.07812 4.8025 4 5.02108 4H18.9789C19.1975 4 19.4083 4.07812 19.6112 4.23438C19.8142 4.39062 19.9391 4.60938 19.9859 4.89062C20.0328 5.17188 19.9625 5.42188 19.7752 5.64062L14.0141 13Z"
      fill="#B4B4B4"
    />
    <circle cx="20" cy="4" r="4" fill="#0062FF" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      default: "#B4B4B4",
      type: String,
    },
  },
  name: "FilterActiveIcon",
};
</script>

<style scoped></style>
