<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M13.5 12C14.75 12 15.8125 12.4375 16.6875 13.3125C17.5625 14.1875 18 15.25 18 16.5C18 17.375 17.7656 18.1719 17.2969 18.8906L20.3906 21.9844L18.9844 23.3906L15.8438 20.2969C15.125 20.7656 14.3438 21 13.5 21C12.25 21 11.1875 20.5625 10.3125 19.6875C9.4375 18.8125 9 17.75 9 16.5C9 15.25 9.4375 14.1875 10.3125 13.3125C11.1875 12.4375 12.25 12 13.5 12ZM13.5 14.0156C12.8125 14.0156 12.2188 14.2578 11.7188 14.7422C11.2187 15.2266 10.9688 15.8125 10.9688 16.5C10.9688 17.1875 11.2187 17.7734 11.7188 18.2578C12.2188 18.7422 12.8125 18.9844 13.5 18.9844C14.1875 18.9844 14.7734 18.7422 15.2578 18.2578C15.7422 17.7734 15.9844 17.1875 15.9844 16.5C15.9844 15.8125 15.7422 15.2266 15.2578 14.7422C14.7734 14.2578 14.1875 14.0156 13.5 14.0156ZM12 6.09375L6 3.98438V15.8906L6.98438 16.2188V16.5C6.98438 17.125 7.07812 17.7344 7.26562 18.3281L6 17.9062L0.65625 19.9688L0.46875 20.0156C0.34375 20.0156 0.234375 19.9609 0.140625 19.8516C0.046875 19.7422 0 19.625 0 19.5V4.35938C0 4.14063 0.109375 3.98438 0.328125 3.89062L6 2.01562L12 4.07812L17.3438 2.01562H17.4844C17.6094 2.01562 17.7266 2.0625 17.8359 2.15625C17.9453 2.25 18 2.35937 18 2.48438V11.8125C17.4062 11.25 16.7187 10.8047 15.9375 10.4766C15.1563 10.1484 14.3438 9.98438 13.5 9.98438C13 9.98438 12.5 10.0469 12 10.1719V6.09375Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MapIcon",
};
</script>

<style scoped></style>
