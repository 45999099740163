<template>
  <div>
    {{ $t("profile.agree_with") }}
    <a
      class="agreement-text_link"
      @click.stop.prevent="handleLegalClicked(links[0])"
      >{{ $t("profile.with_cookie_policy") }}</a
    >,
    <a
      class="agreement-text_link"
      @click.stop.prevent="handleLegalClicked(links[1])"
      >{{ $t("profile.with_disclaimer") }}</a
    >,
    <a
      class="agreement-text_link"
      @click.stop.prevent="handleLegalClicked(links[2])"
      >{{ $t("profile.with_privacy_policy") }}</a
    >,
    {{ $t("profile.and_with") }}
    <a
      class="agreement-text_link"
      @click.stop.prevent="handleLegalClicked(links[3])"
      >{{ $t("profile.with_terms_of_service") }}</a
    >.
  </div>
</template>

<script>
import { eventBus } from "@/main";

import { LINKS } from "@/components/Common/constants";
export default {
  name: "AgreementText",
  computed: {
    links() {
      return LINKS;
    },
  },
  methods: {
    handleLegalClicked(link) {
      eventBus.$emit("legalClick", link);
    },
  },
};
</script>
<style scoped>
.agreement-text_link {
  text-decoration: underline;
}
</style>
