<template>
  <div v-if="isAccess">
    <slot></slot>
  </div>
</template>

<script>
import { getAccess, isAccess, setToken } from "@/services/tokenService";
import { setApiLanguage } from "@/api";
import i18n from "@/i18n";

export default {
  name: "AuthorizationVue",
  data: () => ({
    isAccess,
  }),

  mounted() {
    setApiLanguage(i18n.locale);
    this.$store.getters.tokens && this.$store.getters.isAuthenticated
      ? setToken(
          this.$store.getters.tokens.access_token,
          this.$store.getters.tokens.refresh_token
        )
      : getAccess();
  },
};
</script>

<style></style>
