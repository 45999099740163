<template>
  <v-text-field
    v-model="internalValue"
    :label="$t('email')"
    :outlined="outlined"
    hide-spin-buttons
    :hide-details="hideDetails"
    :disabled="disabled"
    :rules="rules"
    autocomplete="email"
    :error="error"
    :error-messages="errorMessages"
    @change="$emit('change')"
  ></v-text-field>
</template>

<script>
export default {
  name: "EmailInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    rules() {
      return [
        (v) => !!v || this.$t("profile.email_is_required"),
        (v) =>
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) ||
          this.$t("profile.email_must_be_valid"),
      ];
    },
  },
};
</script>
