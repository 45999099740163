<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1538 16.4706H17.5V19H6.5V5H17.5V7.08824H10.1538H9.65385V7.58824V10.2353V10.7353H10.1538H17.5V13.2647H10.1538H9.65385V13.7647V15.9706V16.4706H10.1538Z"
      fill="#FF4C5B"
      stroke="#FF4C5B"
    />
  </svg>
</template>

<script>
export default {
  name: "PharmacyIcon",
};
</script>

<style scoped></style>
