<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5527 2.99705C19.3008 3.00877 19.0635 3.08201 18.8526 3.16405H18.8496C18.6358 3.24901 17.6192 3.67674 16.0752 4.3242C14.5313 4.97459 12.5274 5.81834 10.5381 6.65623C6.56544 8.32908 2.66017 9.97557 2.66017 9.97557L2.70704 9.95799C2.70704 9.95799 2.43751 10.0459 2.15626 10.2392C2.01271 10.333 1.8545 10.4619 1.71681 10.664C1.57911 10.8662 1.46779 11.1767 1.5088 11.4961C1.57618 12.0381 1.92775 12.3633 2.1797 12.542C2.43458 12.7236 2.67775 12.8086 2.67775 12.8086H2.68361L6.34572 14.042C6.50978 14.5693 7.46193 17.6982 7.69044 18.4189C7.82521 18.8496 7.95704 19.1191 8.12111 19.3242C8.20021 19.4297 8.29396 19.5176 8.40529 19.5879C8.44923 19.6142 8.49611 19.6347 8.54298 19.6523C8.55763 19.6611 8.57228 19.664 8.58986 19.667L8.55177 19.6582C8.56349 19.6611 8.57228 19.6699 8.58107 19.6728C8.61036 19.6816 8.63087 19.6846 8.66896 19.6904C9.24904 19.8662 9.71486 19.5058 9.71486 19.5058L9.74122 19.4853L11.9033 17.5166L15.5274 20.2969L15.6094 20.332C16.3652 20.6631 17.1299 20.4785 17.5342 20.1533C17.9414 19.8252 18.0996 19.4062 18.0996 19.4062L18.126 19.3389L20.9268 4.99217C21.0059 4.63768 21.0264 4.30662 20.9385 3.98436C20.8506 3.66209 20.625 3.36033 20.3526 3.1992C20.0772 3.03514 19.8047 2.98533 19.5527 2.99705ZM19.4766 4.53514C19.4736 4.58201 19.4824 4.57616 19.4619 4.66697V4.67576L16.6875 18.873C16.6758 18.8935 16.6553 18.9375 16.5996 18.9814C16.541 19.0283 16.4942 19.0576 16.251 18.9609L11.8184 15.5625L9.14064 18.0029L9.70314 14.4111C9.70314 14.4111 16.6465 7.93944 16.9453 7.66112C17.2442 7.3828 17.1445 7.3242 17.1445 7.3242C17.1651 6.98436 16.6934 7.22459 16.6934 7.22459L7.56154 12.8818L7.55861 12.8672L3.18165 11.3935V11.3906C3.17872 11.3906 3.17286 11.3877 3.16993 11.3877C3.17286 11.3877 3.19337 11.3789 3.19337 11.3789L3.21681 11.3672L3.24025 11.3584C3.24025 11.3584 7.14845 9.7119 11.1211 8.03905C13.1104 7.20116 15.1143 6.35741 16.6553 5.70701C18.1963 5.05955 19.336 4.58494 19.4004 4.55858C19.4619 4.53514 19.4326 4.53514 19.4766 4.53514Z"
      fill="#0062FF"
    />
  </svg>
</template>

<script>
export default {
  name: "TelegramOutlineIcon",
};
</script>

<style scoped></style>
