import axios from "axios";
import { getAccess, token } from "@/services/tokenService";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import { eventBus } from "@/main";

const PAGES_FOR_REDIRECT = ["profile"];

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const instance2 = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let refreshTokenPromise = null;

instance.interceptors.response.use(
  (response) => response,
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        // check for an existing in-progress request
        // if nothing is in-progress, start a new refresh token request
        if (!refreshTokenPromise) {
          originalConfig._retry = true;
          refreshTokenPromise = refreshToken()
            .then((data) => {
              refreshTokenPromise = null; // clear state
              return data; // resolve with the new token
            })
            .catch(async (error) => {
              console.error(error);
              if (store.getters.isAuthenticated) {
                await store.dispatch("setAuthentication", false);
                await store.dispatch("setUser", null);
                await store.dispatch("setTokens", null);
                await getAccess();

                if (PAGES_FOR_REDIRECT.includes(router.currentRoute.name)) {
                  await router.push({
                    name: "auth",
                    params: { lang: i18n.locale },
                  });
                }

                eventBus.$emit("showSnackbar", {
                  text: i18n.t("profile.token_expired"),
                  color: "warning",
                });
                eventBus.$emit("gTagEvent", "logout_token-expired");
              }
            });
        }
        return refreshTokenPromise.then(async (data) => {
          const { access, refresh } = data.data;
          setApiHeaders(access);
          await store.dispatch("setTokens", {
            access_token: access,
            refresh_token: refresh,
          });
          originalConfig.headers = {
            ...originalConfig.headers,
            Authorization: `Bearer ${access}`,
          };
          return instance.request(originalConfig);
        });
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

function refreshToken() {
  return instance2.post("/token/refresh/", {
    refresh: token.value.refresh,
  });
}

export async function setApiHeaders(accessToken) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

export async function setApiLanguage(language) {
  instance.defaults.headers.common["Accept-language"] = language;
}

export const api = instance;
