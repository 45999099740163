<template>
  <v-card-text>
    <h2 class="c13" id="h.mwim9nzev9is">
      <span class="c7 c12">TERMS OF SERVICE</span>
    </h2>
    <p class="c3">
      <span class="c9">Last updated </span><span class="c9">Apr 21, 2023</span>
    </p>
    <p class="c0"><span>1. </span><span class="c4">Introduction</span></p>
    <p class="c0">
      <span>Welcome to </span><span class="c7">TEOXOFT.COM LLC</span
      ><span class="c1"
        >&nbsp;(&ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;,
        &ldquo;us&rdquo;)!</span
      >
    </p>
    <p class="c0">
      <span
        >These Terms of Service (&ldquo;Terms&rdquo;, &ldquo;Terms of
        Service&rdquo;) govern your use of our website located at </span
      ><span class="c7">{{ domain }}</span
      ><span
        >&nbsp;(together or individually &ldquo;Service&rdquo;) operated by </span
      ><span class="c7">TEOXOFT.COM LLC</span><span class="c1">.</span>
    </p>
    <p class="c0">
      <span class="c1"
        >Our Privacy Policy also governs your use of our Service and explains
        how we collect, safeguard and disclose information that results from
        your use of our web pages.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >Your agreement with us includes these Terms and our Privacy Policy
        (&ldquo;Agreements&rdquo;). You acknowledge that you have read and
        understood Agreements, and agree to be bound of them.</span
      >
    </p>
    <p class="c0">
      <span
        >If you do not agree with (or cannot comply with) Agreements, then you
        may not use the Service, but please let us know by emailing at </span
      ><span class="c7">contact@{{ domain }}</span
      ><span class="c1"
        >&nbsp;so we can try to find a solution. These Terms apply to all
        visitors, users and others who wish to access or use Service.</span
      >
    </p>
    <p class="c0"><span>2. </span><span class="c4">Communications</span></p>
    <p class="c0">
      <span class="c1"
        >By using our Service, you agree to subscribe to newsletters, marketing
        or promotional materials and other information we may send. However, you
        may opt out of receiving any, or all, of these communications from us by
        following the unsubscribe link or by emailing at contact@{{
          domain
        }}.</span
      >
    </p>
    <p class="c0">
      <span>3. </span
      ><span class="c4">Contests, Sweepstakes and Promotions</span>
    </p>
    <p class="c0">
      <span class="c1"
        >Any contests, sweepstakes or other promotions (collectively,
        &ldquo;Promotions&rdquo;) made available through Service may be governed
        by rules that are separate from these Terms of Service. If you
        participate in any Promotions, please review the applicable rules as
        well as our Privacy Policy. If the rules for a Promotion conflict with
        these Terms of Service, Promotion rules will apply.</span
      >
    </p>
    <p class="c0"><span>4. </span><span class="c4">Content</span></p>
    <p class="c0">
      <span class="c1"
        >Our Service allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, videos, or other material
        (&ldquo;Content&rdquo;). You are responsible for Content that you post
        on or through Service, including its legality, reliability, and
        appropriateness.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >By posting Content on or through Service, You represent and warrant
        that: (i) Content is yours (you own it) and/or you have the right to use
        it and the right to grant us the rights and license as provided in these
        Terms, and (ii) that the posting of your Content on or through Service
        does not violate the privacy rights, publicity rights, copyrights,
        contract rights or any other rights of any person or entity. We reserve
        the right to terminate the account of anyone found to be infringing on a
        copyright.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >You retain any and all of your rights to any Content you submit, post
        or display on or through Service and you are responsible for protecting
        those rights. We take no responsibility and assume no liability for
        Content you or any third party posts on or through Service. However, by
        posting Content using Service you grant us the right and license to use,
        modify, publicly perform, publicly display, reproduce, and distribute
        such Content on and through Service. You agree that this license
        includes the right for us to make your Content available to other users
        of Service, who may also use your Content subject to these Terms.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >TEOXOFT.COM LLC has the right but not the obligation to monitor and
        edit all Content provided by users.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >In addition, Content found on or through this Service are the property
        of TEOXOFT.COM LLC or used with permission. You may not distribute,
        modify, transmit, reuse, download, repost, copy, or use said Content,
        whether in whole or in part, for commercial purposes or for personal
        gain, without express advance written permission from us.</span
      >
    </p>
    <p class="c0"><span>5. </span><span class="c4">Prohibited Uses</span></p>
    <p class="c0">
      <span class="c1"
        >You may use Service only for lawful purposes and in accordance with
        Terms. You agree not to use Service:</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.1. In any way that violates any applicable national or international
        law or regulation.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.2. For the purpose of exploiting, harming, or attempting to exploit
        or harm minors in any way by exposing them to inappropriate content or
        otherwise.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.3. To transmit, or procure the sending of, any advertising or
        promotional material, including any &ldquo;junk mail&rdquo;,
        &ldquo;chain letter,&rdquo; &ldquo;spam,&rdquo; or any other similar
        solicitation.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.4. To impersonate or attempt to impersonate Company, a Company
        employee, another user, or any other person or entity.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.5. In any way that infringes upon the rights of others, or in any way
        is illegal, threatening, fraudulent, or harmful, or in connection with
        any unlawful, illegal, fraudulent, or harmful purpose or activity.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.6. To engage in any other conduct that restricts or inhibits
        anyone&rsquo;s use or enjoyment of Service, or which, as determined by
        us, may harm or offend Company or users of Service or expose them to
        liability.</span
      >
    </p>
    <p class="c0"><span class="c1">Additionally, you agree not to:</span></p>
    <p class="c0">
      <span class="c1"
        >0.1. Use Service in any manner that could disable, overburden, damage,
        or impair Service or interfere with any other party&rsquo;s use of
        Service, including their ability to engage in real time activities
        through Service.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.2. Use any robot, spider, or other automatic device, process, or
        means to access Service for any purpose, including monitoring or copying
        any of the material on Service.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.3. Use any manual process to monitor or copy any of the material on
        Service or for any other unauthorized purpose without our prior written
        consent.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.4. Use any device, software, or routine that interferes with the
        proper working of Service.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other
        material which is malicious or technologically harmful.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.6. Attempt to gain unauthorized access to, interfere with, damage, or
        disrupt any parts of Service, the server on which Service is stored, or
        any server, computer, or database connected to Service.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.7. Attack Service via a denial-of-service attack or a distributed
        denial-of-service attack.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.8. Take any action that may damage or falsify Company rating.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.9. Otherwise attempt to interfere with the proper working of
        Service.</span
      >
    </p>
    <p class="c0"><span>6. </span><span class="c4">Analytics</span></p>
    <p class="c0">
      <span class="c1"
        >We may use third-party Service Providers to monitor and analyze the use
        of our Service.</span
      >
    </p>
    <p class="c0"><span>7. </span><span class="c4">No Use By Minors</span></p>
    <p class="c0">
      <span class="c1"
        >Service is intended only for access and use by individuals at least
        eighteen (18) years old. By accessing or using Service, you warrant and
        represent that you are at least eighteen (18) years of age and with the
        full authority, right, and capacity to enter into this agreement and
        abide by all of the terms and conditions of Terms. If you are not at
        least eighteen (18) years old, you are prohibited from both the access
        and usage of Service.</span
      >
    </p>
    <p class="c0"><span>8. </span><span class="c4">Accounts</span></p>
    <p class="c0">
      <span class="c1"
        >When you create an account with us, you guarantee that you are above
        the age of 18, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        Service.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you,
        without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >We reserve the right to refuse service, terminate accounts, remove or
        edit content, or cancel orders in our sole discretion.</span
      >
    </p>
    <p class="c0">
      <span>9. </span><span class="c4">Intellectual Property</span>
    </p>
    <p class="c0">
      <span class="c1"
        >Service and its original content (excluding Content provided by users),
        features and functionality are and will remain the exclusive property of
        TEOXOFT.COM LLC and its licensors. Service is protected by copyright,
        trademark, and other laws of and foreign countries. Our trademarks may
        not be used in connection with any product or service without the prior
        written consent of TEOXOFT.COM LLC.</span
      >
    </p>
    <p class="c0"><span>10. </span><span class="c4">Copyright Policy</span></p>
    <p class="c0">
      <span class="c1"
        >We respect the intellectual property rights of others. It is our policy
        to respond to any claim that Content posted on Service infringes on the
        copyright or other intellectual property rights
        (&ldquo;Infringement&rdquo;) of any person or entity.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >If you are a copyright owner, or authorized on behalf of one, and you
        believe that the copyrighted work has been copied in a way that
        constitutes copyright infringement, please submit your claim via email
        to contact@{{ domain }}, with the subject line: &ldquo;Copyright
        Infringement&rdquo; and include in your claim a detailed description of
        the alleged Infringement as detailed below, under &ldquo;DMCA Notice and
        Procedure for Copyright Infringement Claims&rdquo;</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >You may be held accountable for damages (including costs and
        attorneys&rsquo; fees) for misrepresentation or bad-faith claims on the
        infringement of any Content found on and/or through Service on your
        copyright.</span
      >
    </p>
    <p class="c0">
      <span>11. </span
      ><span class="c4"
        >DMCA Notice and Procedure for Copyright Infringement Claims</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >You may submit a notification pursuant to the Digital Millennium
        Copyright Act (DMCA) by providing our Copyright Agent with the following
        information in writing (see 17 U.S.C 512(c)(3) for further
        detail):</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.1. an electronic or physical signature of the person authorized to
        act on behalf of the owner of the copyright&rsquo;s interest;</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.2. a description of the copyrighted work that you claim has been
        infringed, including the URL (i.e., web page address) of the location
        where the copyrighted work exists or a copy of the copyrighted
        work;</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.3. identification of the URL or other specific location on Service
        where the material that you claim is infringing is located;</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.4. your address, telephone number, and email address;</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.5. a statement by you that you have a good faith belief that the
        disputed use is not authorized by the copyright owner, its agent, or the
        law;</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >0.6. a statement by you, made under penalty of perjury, that the above
        information in your notice is accurate and that you are the copyright
        owner or authorized to act on the copyright owner&rsquo;s behalf.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >You can contact our Copyright Agent via email at contact@{{
          domain
        }}.</span
      >
    </p>
    <p class="c0">
      <span>12. </span><span class="c4">Error Reporting and Feedback</span>
    </p>
    <p class="c0">
      <span class="c1"
        >You may provide us either directly at contact@{{ domain }} or via third
        party sites and tools with information and feedback concerning errors,
        suggestions for improvements, ideas, problems, complaints, and other
        matters related to our Service (&ldquo;Feedback&rdquo;). You acknowledge
        and agree that: (i) you shall not retain, acquire or assert any
        intellectual property right or other right, title or interest in or to
        the Feedback; (ii) Company may have development ideas similar to the
        Feedback; (iii) Feedback does not contain confidential information or
        proprietary information from you or any third party; and (iv) Company is
        not under any obligation of confidentiality with respect to the
        Feedback. In the event the transfer of the ownership to the Feedback is
        not possible due to applicable mandatory laws, you grant Company and its
        affiliates an exclusive, transferable, irrevocable, free-of-charge,
        sub-licensable, unlimited and perpetual right to use (including copy,
        modify, create derivative works, publish, distribute and commercialize)
        Feedback in any manner and for any purpose.</span
      >
    </p>
    <p class="c0">
      <span>13. </span><span class="c4">Links To Other Web Sites</span>
    </p>
    <p class="c0">
      <span class="c1"
        >Our Service may contain links to third party web sites or services that
        are not owned or controlled by TEOXOFT.COM LLC.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >TEOXOFT.COM LLC has no control over, and assumes no responsibility for
        the content, privacy policies, or practices of any third party web sites
        or services. We do not warrant the offerings of any of these
        entities/individuals or their websites.</span
      >
    </p>
    <p class="c0">
      <span>For example, the outlined</span
      ><span
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io/terms-and-conditions/&amp;sa=D&amp;source=editors&amp;ust=1682373159257434&amp;usg=AOvVaw2pS8cdWj1KbhAOfTRer87g"
          >&nbsp;</a
        ></span
      ><span class="c10"
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io/terms-and-conditions/&amp;sa=D&amp;source=editors&amp;ust=1682373159257642&amp;usg=AOvVaw0NQ14ji05WvIBDHX0lc-mB"
          >Terms of Use</a
        ></span
      ><span>&nbsp;have been created using</span
      ><span
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io/&amp;sa=D&amp;source=editors&amp;ust=1682373159257793&amp;usg=AOvVaw2QjeaTIg8cBuIrBQoONfJe"
          >&nbsp;</a
        ></span
      ><span class="c10"
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io/&amp;sa=D&amp;source=editors&amp;ust=1682373159257926&amp;usg=AOvVaw1oCJ8avBnfhbVp3NGoOSOa"
          >PolicyMaker.io</a
        ></span
      ><span
        >, a free web application for generating high-quality legal documents.
        PolicyMaker&rsquo;s</span
      ><span
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io/terms-and-conditions/&amp;sa=D&amp;source=editors&amp;ust=1682373159258101&amp;usg=AOvVaw1k29wvxsEbhF7oSksoOWFr"
          >&nbsp;</a
        ></span
      ><span class="c10"
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io/terms-and-conditions/&amp;sa=D&amp;source=editors&amp;ust=1682373159258233&amp;usg=AOvVaw3s8_Te0DlBn-5eezQDoOcL"
          >Terms and Conditions generator</a
        ></span
      ><span class="c1"
        >&nbsp;is an easy-to-use free tool for creating an excellent standard
        Terms of Service template for a website, blog, e-commerce store or
        app.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR
        LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED
        TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH
        CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY
        WEB SITES OR SERVICES.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
        POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</span
      >
    </p>
    <p class="c0">
      <span>14. </span><span class="c4">Disclaimer Of Warranty</span>
    </p>
    <p class="c0">
      <span class="c1"
        >THESE SERVICES ARE PROVIDED BY COMPANY ON AN &ldquo;AS IS&rdquo; AND
        &ldquo;AS AVAILABLE&rdquo; BASIS. COMPANY MAKES NO REPRESENTATIONS OR
        WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR
        SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
        EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY
        SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
        WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
        LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
        COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
        RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
        THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES
        OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
        EXPECTATIONS.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
        PARTICULAR PURPOSE.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
        OR LIMITED UNDER APPLICABLE LAW.</span
      >
    </p>
    <p class="c0">
      <span>15. </span><span class="c4">Limitation Of Liability</span>
    </p>
    <p class="c0">
      <span class="c1"
        >EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
        (INCLUDING ATTORNEYS&rsquo; FEES AND ALL RELATED COSTS AND EXPENSES OF
        LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
        NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
        CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
        CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
        FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND
        ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
        RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF
        THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
        LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
        PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
        THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
        DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO
        YOU.</span
      >
    </p>
    <p class="c0"><span>16. </span><span class="c4">Termination</span></p>
    <p class="c0">
      <span class="c1"
        >We may terminate or suspend your account and bar access to Service
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of Terms.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >If you wish to terminate your account, you may simply discontinue using
        Service.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >All provisions of Terms which by their nature should survive
        termination shall survive termination, including, without limitation,
        ownership provisions, warranty disclaimers, indemnity and limitations of
        liability.</span
      >
    </p>
    <p class="c0"><span>17. </span><span class="c4">Governing Law</span></p>
    <p class="c0">
      <span class="c1"
        >These Terms shall be governed and construed in accordance with the laws
        of the state of Wyoming, United States of America, which governing law
        applies to agreement without regard to its conflict of law
        provisions.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >Our failure to enforce any right or provision of these Terms will not
        be considered a waiver of those rights. If any provision of these Terms
        is held to be invalid or unenforceable by a court, the remaining
        provisions of these Terms will remain in effect. These Terms constitute
        the entire agreement between us regarding our Service and supersede and
        replace any prior agreements we might have had between us regarding
        Service.</span
      >
    </p>
    <p class="c0">
      <span>18. </span><span class="c4">Changes To Service</span>
    </p>
    <p class="c0">
      <span class="c1"
        >We reserve the right to withdraw or amend our Service, and any service
        or material we provide via Service, in our sole discretion without
        notice. We will not be liable if for any reason all or any part of
        Service is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of Service, or the entire Service,
        to users, including registered users.</span
      >
    </p>
    <p class="c0">
      <span>19. </span><span class="c4">Amendments To Terms</span>
    </p>
    <p class="c0">
      <span class="c1"
        >We may amend Terms at any time by posting the amended terms on this
        site. It is your responsibility to review these Terms
        periodically.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >Your continued use of the Platform following the posting of revised
        Terms means that you accept and agree to the changes. You are expected
        to check this page frequently so you are aware of any changes, as they
        are binding on you.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >By continuing to access or use our Service after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use
        Service.</span
      >
    </p>
    <p class="c0">
      <span>20. </span><span class="c4">Waiver And Severability</span>
    </p>
    <p class="c0">
      <span class="c1"
        >No waiver by Company of any term or condition set forth in Terms shall
        be deemed a further or continuing waiver of such term or condition or a
        waiver of any other term or condition, and any failure of Company to
        assert a right or provision under Terms shall not constitute a waiver of
        such right or provision.</span
      >
    </p>
    <p class="c0">
      <span class="c1"
        >If any provision of Terms is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of Terms will continue in full
        force and effect.</span
      >
    </p>
    <p class="c0"><span>21. </span><span class="c4">Acknowledgement</span></p>
    <p class="c0">
      <span class="c1"
        >BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT
        YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
        THEM.</span
      >
    </p>
    <p class="c0"><span>22. </span><span class="c4">Contact Us</span></p>
    <p class="c0">
      <span
        >Please send your feedback, comments, requests for technical support by
        email: </span
      ><span class="c7">contact@{{ domain }}</span
      ><span class="c1">.</span>
    </p>
    <p class="c8">
      <span class="c2">These</span
      ><span class="c2"
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io/terms-and-conditions/&amp;sa=D&amp;source=editors&amp;ust=1682373159263611&amp;usg=AOvVaw3nS-BjXkDK9cV6H-Vw-ne1"
          >&nbsp;</a
        ></span
      ><span class="c10 c2"
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io/terms-and-conditions/&amp;sa=D&amp;source=editors&amp;ust=1682373159263769&amp;usg=AOvVaw3ffguh3JQEmdEOGQOZ1TYq"
          >Terms of Service</a
        ></span
      ><span class="c2">&nbsp;were created for </span
      ><span class="c7 c2">{{ domain }}</span
      ><span class="c2">&nbsp;by</span
      ><span class="c2"
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io&amp;sa=D&amp;source=editors&amp;ust=1682373159263967&amp;usg=AOvVaw01Hv7lHrvY-XC0KdL68IZG"
          >&nbsp;</a
        ></span
      ><span class="c2 c10"
        ><a
          class="c5"
          href="https://www.google.com/url?q=https://policymaker.io&amp;sa=D&amp;source=editors&amp;ust=1682373159264069&amp;usg=AOvVaw3FlkMhKxKLSBZ7eWD5f7lp"
          >PolicyMaker.io</a
        ></span
      ><span class="c2 c14">&nbsp;on 2023-04-21.</span>
    </p>
    <p class="c11"><span class="c1"></span></p>
  </v-card-text>
</template>

<script>
export default {
  name: "LegalTac",
  computed: {
    domain() {
      return process.env.VUE_APP_DOMAIN;
    },
  },
};
</script>

<style scoped>
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c3 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c4 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c0 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c14 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}
.c12 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 17pt;
  font-family: "Arial";
  font-style: normal;
}
.c8 {
  padding-top: 42pt;
  padding-bottom: 8pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c13 {
  padding-top: 18pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c10 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c6 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c5 {
  color: inherit;
  text-decoration: inherit;
}
.c9 {
  color: #595959;
  font-size: 10.5pt;
}
.c7 {
  font-weight: 700;
}
.c2 {
  font-size: 7.5pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
