import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import secureLS from "secure-ls";

const ls = new secureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    user: null,
    tokens: null,
    ilceData: null,
    mahalleData: null,
    searchQueryData: null,
  },
  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },
    setUser(state, user) {
      state.user = user;
    },
    setTokens(state, tokens) {
      state.tokens = tokens;
    },
    setIlceData(state, tokens) {
      state.ilceData = tokens;
    },
    setMahalleData(state, tokens) {
      state.mahalleData = tokens;
    },
    setSearchQueryData(state, tokens) {
      state.searchQueryData = tokens;
    },
  },
  actions: {
    setAuthentication({ commit }, status) {
      commit("setAuthentication", status);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setTokens({ commit }, tokens) {
      commit("setTokens", tokens);
    },
    setIlceData({ commit }, tokens) {
      commit("setIlceData", tokens);
    },
    setMahalleData({ commit }, tokens) {
      commit("setMahalleData", tokens);
    },
    setSearchQueryData({ commit }, tokens) {
      commit("setSearchQueryData", tokens);
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    tokens: (state) => state.tokens,
    ilceData: (state) => state.ilceData,
    mahalleData: (state) => state.mahalleData,
    searchQueryData: (state) => state.searchQueryData,
  },
  plugins: [
    createPersistedState({
      paths: ["isAuthenticated", "user", "tokens"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
