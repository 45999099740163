<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.26786 13.5H6.30357C5.97946 13.5 5.71429 13.2469 5.71429 12.9375V11.0625C5.71429 10.7531 5.97946 10.5 6.30357 10.5H8.26786C8.59196 10.5 8.85714 10.7531 8.85714 11.0625V12.9375C8.85714 13.2469 8.59196 13.5 8.26786 13.5ZM13.5714 12.9375V11.0625C13.5714 10.7531 13.3062 10.5 12.9821 10.5H11.0179C10.6937 10.5 10.4286 10.7531 10.4286 11.0625V12.9375C10.4286 13.2469 10.6937 13.5 11.0179 13.5H12.9821C13.3062 13.5 13.5714 13.2469 13.5714 12.9375ZM18.2857 12.9375V11.0625C18.2857 10.7531 18.0205 10.5 17.6964 10.5H15.7321C15.408 10.5 15.1429 10.7531 15.1429 11.0625V12.9375C15.1429 13.2469 15.408 13.5 15.7321 13.5H17.6964C18.0205 13.5 18.2857 13.2469 18.2857 12.9375ZM13.5714 17.4375V15.5625C13.5714 15.2531 13.3062 15 12.9821 15H11.0179C10.6937 15 10.4286 15.2531 10.4286 15.5625V17.4375C10.4286 17.7469 10.6937 18 11.0179 18H12.9821C13.3062 18 13.5714 17.7469 13.5714 17.4375ZM8.85714 17.4375V15.5625C8.85714 15.2531 8.59196 15 8.26786 15H6.30357C5.97946 15 5.71429 15.2531 5.71429 15.5625V17.4375C5.71429 17.7469 5.97946 18 6.30357 18H8.26786C8.59196 18 8.85714 17.7469 8.85714 17.4375ZM18.2857 17.4375V15.5625C18.2857 15.2531 18.0205 15 17.6964 15H15.7321C15.408 15 15.1429 15.2531 15.1429 15.5625V17.4375C15.1429 17.7469 15.408 18 15.7321 18H17.6964C18.0205 18 18.2857 17.7469 18.2857 17.4375ZM23 5.25V21.75C23 22.9922 21.9442 24 20.6429 24H3.35714C2.0558 24 1 22.9922 1 21.75V5.25C1 4.00781 2.0558 3 3.35714 3H5.71429V0.5625C5.71429 0.253125 5.97946 0 6.30357 0H8.26786C8.59196 0 8.85714 0.253125 8.85714 0.5625V3H15.1429V0.5625C15.1429 0.253125 15.408 0 15.7321 0H17.6964C18.0205 0 18.2857 0.253125 18.2857 0.5625V3H20.6429C21.9442 3 23 4.00781 23 5.25ZM20.6429 21.4688V7.5H3.35714V21.4688C3.35714 21.6234 3.48973 21.75 3.65179 21.75H20.3482C20.5103 21.75 20.6429 21.6234 20.6429 21.4688Z"
      fill="#D7D7D7"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarIcon",
};
</script>

<style scoped></style>
