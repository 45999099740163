<template>
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.7567 48.1748C94.4512 48.1748 102.877 51.1896 109.598 56.7054C116.319 62.2212 120.919 69.8968 122.615 78.4243C124.312 86.9518 122.999 95.8036 118.9 103.472C114.801 111.139 108.171 117.149 100.138 120.476C92.1052 123.803 83.1673 124.243 74.8471 121.719C66.527 119.195 59.3393 113.864 54.5089 106.635C49.6785 99.4053 47.5042 90.7248 48.3564 82.0721C49.2087 73.4194 53.0348 65.3299 59.1828 59.1819C62.6643 55.6811 66.8056 52.9057 71.3671 51.0163C75.9285 49.1269 80.8194 48.1611 85.7567 48.1748ZM85.7567 24.6866C52.0277 24.6866 24.6875 52.0268 24.6875 85.7558C24.6875 119.485 52.0277 146.825 85.7567 146.825C119.486 146.825 146.826 119.485 146.826 85.7558C146.826 52.0268 119.486 24.6866 85.7567 24.6866Z"
      fill="#F5F5F5"
    />
    <path
      d="M172.925 164.646L164.616 172.955C163.964 173.611 163.189 174.131 162.335 174.487C161.481 174.842 160.565 175.025 159.64 175.025C158.715 175.025 157.799 174.842 156.945 174.487C156.091 174.131 155.315 173.611 154.663 172.955L125.391 143.683C124.737 143.026 124.219 142.248 123.867 141.392C123.514 140.535 123.334 139.618 123.336 138.691V133.906L133.906 123.336H138.691C139.618 123.334 140.535 123.514 141.392 123.867C142.248 124.219 143.026 124.737 143.683 125.391L172.955 154.663C174.27 155.993 175.005 157.79 174.999 159.661C174.994 161.531 174.248 163.323 172.925 164.646Z"
      fill="#F5F5F5"
    />
  </svg>
</template>

<script>
export default {
  name: "MagnifyIcon",
};
</script>

<style scoped></style>
