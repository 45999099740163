import { ref } from "vue";
import { api, setApiHeaders } from "@/api";

const TOKEN_URL = "/token/";
export const token = ref({ refresh: "" });
export const isAccess = ref(false);

export async function getAccess() {
  const tokenUser = {
    username: process.env.VUE_APP_TOKEN_USERNAME,
    password: process.env.VUE_APP_TOKEN_PASS,
  };

  try {
    const {
      data: { refresh, access },
    } = await api.post(TOKEN_URL, tokenUser);

    isAccess.value = true;

    token.value = { refresh };

    await setApiHeaders(access);
  } catch (err) {
    console.log("err", err);
  }
}

export async function setToken(access, refresh) {
  try {
    isAccess.value = true;
    token.value = { refresh };
    await setApiHeaders(access);
  } catch (err) {
    console.log("err", err);
  }
}
