<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8 6.65H16.25V1.2C16.25 0.868628 15.9814 0.6 15.65 0.6H8.9C8.56863 0.6 8.3 0.868629 8.3 1.2V1.9C8.3 2.23137 8.56863 2.5 8.9 2.5H14.35V11.6H12.75C12.4186 11.6 12.15 11.8686 12.15 12.2V12.9C12.15 13.2314 12.4186 13.5 12.75 13.5H17.85C18.1814 13.5 18.45 13.2314 18.45 12.9V12.2C18.45 11.8686 18.1814 11.6 17.85 11.6H16.25V8.55H21.5V21.5H16.25V17.15C16.25 16.8186 15.9814 16.55 15.65 16.55H14.95C14.6186 16.55 14.35 16.8186 14.35 17.15V21.5H2.5V13.5H5.75C6.08137 13.5 6.35 13.2314 6.35 12.9V12.2C6.35 11.8686 6.08137 11.6 5.75 11.6H2.5V2.5H5.75C6.08137 2.5 6.35 2.23137 6.35 1.9V1.2C6.35 0.868629 6.08137 0.6 5.75 0.6H1.2C0.868629 0.6 0.6 0.868629 0.6 1.2V22.8C0.6 23.1314 0.868626 23.4 1.2 23.4H22.8C23.1314 23.4 23.4 23.1314 23.4 22.8V7.25C23.4 6.91863 23.1314 6.65 22.8 6.65Z"
      fill="#D7D7D7"
      stroke="#D7D7D7"
      stroke-width="0.8"
    />
  </svg>
</template>

<script>
export default {
  name: "ApartmentLayoutIcon",
};
</script>

<style scoped></style>
