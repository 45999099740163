<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.0201 13.5483L21.9055 12.4412C21.9403 12.3975 21.9664 12.3472 21.9823 12.2931C21.9981 12.239 22.0034 12.1823 21.9979 12.1261C21.9923 12.0699 21.976 12.0154 21.9499 11.9657C21.9238 11.916 21.8884 11.8721 21.8458 11.8365L18.943 9.37543V4.42858C18.943 4.31491 18.8991 4.2059 18.821 4.12553C18.7429 4.04515 18.6369 4 18.5264 4H16.582C16.4715 4 16.3655 4.04515 16.2873 4.12553C16.2092 4.2059 16.1653 4.31491 16.1653 4.42858V7.02182L13.0574 4.39286C12.759 4.14035 12.3847 4.00229 11.9983 4.00229C11.612 4.00229 11.2377 4.14035 10.9393 4.39286L2.15125 11.838C2.06609 11.9103 2.01236 12.0145 2.00188 12.1277C1.9914 12.2408 2.02502 12.3536 2.09535 12.4412L2.98075 13.5483C3.05092 13.6361 3.15213 13.6917 3.26211 13.7027C3.37209 13.7138 3.48184 13.6794 3.5672 13.6073L11.7345 6.68611C11.8097 6.62372 11.9034 6.5897 12.0001 6.5897C12.0967 6.5897 12.1905 6.62372 12.2657 6.68611L20.4333 13.6073C20.4756 13.6431 20.5243 13.6699 20.5767 13.6863C20.6291 13.7028 20.6841 13.7084 20.7386 13.7029C20.7931 13.6974 20.846 13.681 20.8943 13.6544C20.9426 13.6279 20.9854 13.5919 21.0201 13.5483ZM13.6651 15.9999V19.4139C13.6646 19.4892 13.6786 19.5639 13.7063 19.6337C13.7341 19.7034 13.7749 19.7668 13.8265 19.8203C13.8782 19.8737 13.9396 19.9161 14.0072 19.9451C14.0748 19.974 14.1474 19.9889 14.2206 19.9889L18.1094 20C18.2568 20 18.3981 19.9398 18.5023 19.8326C18.6065 19.7254 18.665 19.5801 18.665 19.4285V13.5713L12.2654 8.15018C12.1902 8.08779 12.0964 8.05377 11.9998 8.05377C11.9032 8.05377 11.8094 8.08779 11.7342 8.15018L5.33183 13.5735V19.4285C5.33183 19.5801 5.39036 19.7254 5.49455 19.8326C5.59873 19.9398 5.74004 20 5.88738 20L9.779 19.9896C9.92586 19.9889 10.0664 19.9283 10.17 19.8212C10.2736 19.7142 10.3318 19.5692 10.3318 19.4182V15.9999C10.3318 15.8484 10.3903 15.703 10.4945 15.5959C10.5987 15.4887 10.74 15.4285 10.8873 15.4285H13.1095C13.2568 15.4285 13.3982 15.4887 13.5023 15.5959C13.6065 15.703 13.6651 15.8484 13.6651 15.9999Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "HomeIcon",
  props: {
    color: {
      type: String,
      default: "#0062FF",
    },
  },
};
</script>

<style scoped></style>
