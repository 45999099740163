<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22Z"
      fill="#FF4C5B"
    />
    <path
      d="M15.333 35.332V19.332H19.5997V25.732H24.3997V19.332H28.133V35.332H24.3997V29.4654H19.5997V35.332H15.333Z"
      fill="white"
    />
    <path
      d="M36.5278 20.8379L35.2292 22.6094C35.1782 22.679 35.1155 22.7367 35.0446 22.7791C34.9738 22.8216 34.8962 22.8479 34.8163 22.8567C34.7363 22.8654 34.6556 22.8564 34.5788 22.8302C34.502 22.8039 34.4305 22.7609 34.3685 22.7037L22.3894 11.6298C22.279 11.53 22.1415 11.4756 21.9998 11.4756C21.858 11.4756 21.7206 11.53 21.6102 11.6298L9.63157 22.7037C9.50637 22.8191 9.34541 22.8741 9.18411 22.8564C9.0228 22.8387 8.87436 22.7499 8.77145 22.6094L7.47285 20.8379C7.3697 20.6977 7.32039 20.5173 7.33577 20.3363C7.35114 20.1553 7.42994 19.9886 7.55484 19.8728L20.444 7.96061C20.8816 7.55659 21.4306 7.3357 21.9972 7.3357C22.5639 7.3357 23.1129 7.55659 23.5505 7.96061L28.1088 12.167V8.01775C28.1088 7.83589 28.1732 7.66147 28.2878 7.53287C28.4024 7.40428 28.5578 7.33203 28.7199 7.33203H31.5717C31.7338 7.33203 31.8892 7.40428 32.0038 7.53287C32.1184 7.66147 32.1828 7.83589 32.1828 8.01775V15.9327L36.4402 19.8705C36.5027 19.9274 36.5546 19.9976 36.5929 20.0771C36.6312 20.1566 36.6551 20.2439 36.6632 20.3338C36.6714 20.4237 36.6636 20.5145 36.6403 20.601C36.6171 20.6875 36.5788 20.768 36.5278 20.8379Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "HospitalIcon",
};
</script>

<style scoped></style>
