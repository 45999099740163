<template>
  <svg
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg2"
    width="1843.3011"
    height="178.25049"
    viewBox="0 0 1843.3011 178.25049"
    sodipodi:docname="TiL_logo.ai"
  >
    <g
      id="g10"
      inkscape:groupmode="layer"
      inkscape:label="Page 1"
      transform="matrix(1.3333333,0,0,-1.3333333,-959.91889,965.952)"
    >
      <path
        d="m 759.8672,592.2039 v 101.244 h -39.928 v 23.529 h 108.731 v -23.529 h -39.927 v -101.244 z"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path28"
      />
      <path
        d="m 936.6906,688.1062 v -95.9 h -26.38 v 11.25 c -2.6,-3.09 -5.72,-5.62 -9.36,-7.6 -6.24,-3.38 -13.04,-5.08 -20.41,-5.08 -7.96,0 -15.06,1.54 -21.3,4.64 -6.24,3.08 -11.08,7.81 -14.53,14.17 -3.44,6.35 -5.17,14.46 -5.17,24.33 v 54.19 h 27.81 v -50.09 c 0,-7.97 1.69,-13.82 5.08,-17.56 3.39,-3.74 8.17,-5.61 14.35,-5.61 4.28,0 8.08,0.91 11.41,2.76 3.32,1.84 5.94,4.69 7.84,8.55 1.9,3.86 2.85,8.71 2.85,14.53 v 47.42 z"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path40"
        clip-path="url(#clipPath1801)"
      />
      <path
        d="m 1022.0706,689.5261 v -25.67 c -1.19,0.12 -2.26,0.21 -3.21,0.27 -0.95,0.06 -1.96,0.09 -3.03,0.09 -7.61,0 -13.76,-2.17 -18.45,-6.51 -4.69,-4.34 -7.04,-11.08 -7.04,-20.23 v -45.27 h -27.81 v 95.9 h 26.56 v -12.59 c 2.62,3.59 5.94,6.51 9.98,8.75 6.3,3.51 13.97,5.26 23,5.26"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path44"
        clip-path="url(#clipPath1816)"
      />
      <path
        d="m 1136.6804,647.1062 v -54.9 h -27.8 v 50.62 c 0,7.72 -1.7,13.43 -5.08,17.11 -3.39,3.68 -8.18,5.53 -14.35,5.53 -4.4,0 -8.35,-0.92 -11.86,-2.77 -3.5,-1.84 -6.24,-4.66 -8.2,-8.46 -1.96,-3.81 -2.94,-8.68 -2.94,-14.62 v -47.41 h -27.8 v 95.9 h 26.55 v -11.41 c 2.75,3.07 6.02,5.62 9.81,7.66 6.42,3.45 13.72,5.17 21.92,5.17 7.61,0 14.41,-1.51 20.41,-4.54 6,-3.03 10.72,-7.67 14.17,-13.91 3.45,-6.24 5.17,-14.23 5.17,-23.97"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path48"
        clip-path="url(#clipPath1831)"
      />
      <path
        d="m 1214.217,716.977 h 28.876 V 592.204 h -28.876 z"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path50"
        clip-path="url(#clipPath1000)"
      />
      <path
        d="m 1368.7605,647.1062 v -54.9 h -27.81 v 50.62 c 0,7.72 -1.69,13.43 -5.08,17.11 -3.39,3.68 -8.17,5.53 -14.35,5.53 -4.4,0 -8.35,-0.92 -11.85,-2.77 -3.51,-1.84 -6.24,-4.66 -8.2,-8.46 -1.96,-3.81 -2.94,-8.68 -2.94,-14.62 v -47.41 h -27.81 v 95.9 h 26.56 v -11.4 c 2.74,3.06 6.01,5.61 9.8,7.65 6.42,3.45 13.73,5.17 21.93,5.17 7.6,0 14.4,-1.51 20.41,-4.54 5.99,-3.03 10.72,-7.67 14.17,-13.91 3.44,-6.24 5.17,-14.23 5.17,-23.97"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path54"
        clip-path="url(#clipPath1846)"
      />
      <path
        d="m 1455.7405,596.8362 c -2.85,-2.02 -6.27,-3.53 -10.25,-4.54 -3.98,-1.01 -8.11,-1.52 -12.39,-1.52 -11.29,0 -20.08,2.88 -26.38,8.65 -6.3,5.76 -9.45,14.35 -9.45,25.75 v 39.4 h -14.79 v 21.39 h 14.79 v 23.35 h 27.81 v -23.35 h 23.89 v -21.39 h -23.89 v -39.04 c 0,-4.04 1.07,-7.16 3.21,-9.36 2.14,-2.2 5.05,-3.3 8.73,-3.3 4.4,0 8.14,1.19 11.23,3.57 z"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path58"
        clip-path="url(#clipPath1861)"
      />
      <path
        d="m 1514.0269,613.5935 c 4.635,0 8.793,1.069 12.478,3.209 3.681,2.139 6.595,5.196 8.734,9.18 2.139,3.979 3.208,8.703 3.208,14.17 0,5.585 -1.069,10.338 -3.208,14.259 -2.139,3.922 -5.053,6.953 -8.734,9.091 -3.685,2.14 -7.843,3.209 -12.478,3.209 -4.634,0 -8.795,-1.069 -12.477,-3.209 -3.685,-2.138 -6.626,-5.169 -8.823,-9.091 -2.2,-3.921 -3.298,-8.674 -3.298,-14.259 0,-5.467 1.098,-10.191 3.298,-14.17 2.197,-3.984 5.138,-7.041 8.823,-9.18 3.682,-2.14 7.843,-3.209 12.477,-3.209 m 0,-22.815 c -10.221,0 -19.281,2.138 -27.183,6.417 -7.904,4.277 -14.142,10.128 -18.715,17.556 -4.577,7.426 -6.863,15.893 -6.863,25.401 0,9.626 2.286,18.151 6.863,25.579 4.573,7.425 10.811,13.249 18.715,17.467 7.902,4.217 16.962,6.329 27.183,6.329 10.1,0 19.131,-2.112 27.094,-6.329 7.96,-4.218 14.199,-10.012 18.716,-17.378 4.514,-7.37 6.773,-15.925 6.773,-25.668 0,-9.508 -2.259,-17.975 -6.773,-25.401 -4.517,-7.428 -10.756,-13.279 -18.716,-17.556 -7.963,-4.279 -16.994,-6.417 -27.094,-6.417"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path62"
        clip-path="url(#clipPath1876)"
      />
      <path
        d="m 1637.5503,592.2039 v 124.773 h 28.876 v -101.245 h 62.564 v -23.528 z"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path66"
        clip-path="url(#clipPath1891)"
      />
      <path
        d="m 1788.1675,613.5935 c 4.635,0 8.793,1.069 12.478,3.209 3.681,2.139 6.595,5.196 8.734,9.18 2.139,3.979 3.208,8.703 3.208,14.17 0,5.585 -1.069,10.338 -3.208,14.259 -2.139,3.922 -5.053,6.953 -8.734,9.091 -3.685,2.14 -7.843,3.209 -12.478,3.209 -4.634,0 -8.795,-1.069 -12.477,-3.209 -3.685,-2.138 -6.626,-5.169 -8.823,-9.091 -2.2,-3.921 -3.298,-8.674 -3.298,-14.259 0,-5.467 1.098,-10.191 3.298,-14.17 2.197,-3.984 5.138,-7.041 8.823,-9.18 3.682,-2.14 7.843,-3.209 12.477,-3.209 m 0,-22.815 c -10.221,0 -19.281,2.138 -27.183,6.417 -7.904,4.277 -14.142,10.128 -18.715,17.556 -4.577,7.426 -6.863,15.893 -6.863,25.401 0,9.626 2.286,18.151 6.863,25.579 4.573,7.425 10.811,13.249 18.715,17.467 7.902,4.217 16.962,6.329 27.183,6.329 10.1,0 19.131,-2.112 27.094,-6.329 7.96,-4.218 14.199,-10.012 18.716,-17.378 4.514,-7.37 6.773,-15.925 6.773,-25.668 0,-9.508 -2.259,-17.975 -6.773,-25.401 -4.517,-7.428 -10.756,-13.279 -18.716,-17.556 -7.963,-4.279 -16.994,-6.417 -27.094,-6.417"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path70"
        clip-path="url(#clipPath1906)"
      />
      <path
        d="m 1905.6323,590.7781 c -10.338,0 -19.548,2.108 -27.628,6.327 -8.082,4.217 -14.41,10.071 -18.983,17.558 -4.577,7.486 -6.863,15.981 -6.863,25.489 0,9.626 2.286,18.151 6.863,25.579 4.573,7.425 10.901,13.249 18.983,17.468 8.08,4.217 17.29,6.328 27.628,6.328 10.1,0 18.895,-2.111 26.381,-6.328 7.486,-4.219 13.013,-10.249 16.577,-18.092 l -21.567,-11.586 c -2.496,4.515 -5.616,7.843 -9.359,9.982 -3.743,2.139 -7.815,3.209 -12.21,3.209 -4.754,0 -9.032,-1.07 -12.834,-3.209 -3.804,-2.139 -6.803,-5.169 -9.001,-9.091 -2.2,-3.921 -3.298,-8.675 -3.298,-14.26 0,-5.587 1.098,-10.338 3.298,-14.26 2.198,-3.921 5.197,-6.951 9.001,-9.09 3.802,-2.139 8.08,-3.209 12.834,-3.209 4.395,0 8.467,1.039 12.21,3.12 3.743,2.078 6.863,5.436 9.359,10.071 l 21.567,-11.765 c -3.564,-7.725 -9.091,-13.697 -16.577,-17.914 -7.486,-4.219 -16.281,-6.327 -26.381,-6.327"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path74"
        clip-path="url(#clipPath1921)"
      />
      <path
        d="m 2013.6505,612.7961 c -3.69,-2.2 -7.85,-3.3 -12.48,-3.3 -4.87,0 -8.71,1.04 -11.5,3.12 -2.79,2.08 -4.19,4.9 -4.19,8.46 0,3.21 1.22,5.89 3.66,8.03 2.43,2.13 6.92,3.2 13.46,3.2 h 19.07 v -9.8 c -1.67,-4.28 -4.34,-7.52 -8.02,-9.71 m 23.7,66.39 c -8.08,6.89 -19.42,10.34 -34.04,10.34 -7.61,0 -15.09,-1.01 -22.46,-3.03 -7.37,-2.02 -13.67,-4.87 -18.89,-8.55 l 9.98,-19.43 c 3.44,2.73 7.63,4.9 12.56,6.5 4.93,1.61 9.96,2.41 15.07,2.41 7.48,0 13.04,-1.67 16.66,-4.99 3.63,-3.33 5.44,-7.97 5.44,-13.91 h -22.1 c -9.75,0 -17.65,-1.22 -23.71,-3.65 -6.06,-2.44 -10.49,-5.79 -13.28,-10.07 -2.79,-4.28 -4.19,-9.27 -4.19,-14.97 0,-5.47 1.43,-10.4 4.28,-14.8 2.85,-4.4 6.95,-7.87 12.3,-10.43 5.35,-2.55 11.76,-3.83 19.25,-3.83 8.44,0 15.33,1.61 20.68,4.81 3.61,2.17 6.45,5.01 8.55,8.54 v -11.92 h 26.03 v 54.72 c 0,14.62 -4.05,25.37 -12.13,32.26"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path78"
        clip-path="url(#clipPath1936)"
      />
      <path
        d="m 2074.608,724.464 h 27.807 v -132.26 h -27.807 z"
        style="fill: #ffcb04; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        id="path80"
        clip-path="url(#clipPath952)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "TextLogoIcon",
};
</script>

<style scoped></style>
