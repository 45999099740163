<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18.5913 9.09112L12.4089 15.2734L6.22662 9.09112"
      stroke="#8C8C8C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "DownArrowIcon",
};
</script>

<style scoped></style>
