<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.04 2C6.57999 2 2.12999 6.45 2.12999 11.91C2.12999 13.66 2.58999 15.36 3.44999 16.86L2.04999 22L7.29999 20.62C8.74999 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2ZM12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.10999 19.76 7.84999 19L7.54999 18.83L4.42999 19.65L5.25999 16.61L5.05999 16.29C4.23999 15 3.79999 13.47 3.79999 11.91C3.80999 7.37 7.49999 3.67 12.05 3.67ZM8.52999 7.33C8.36999 7.33 8.09999 7.39 7.86999 7.64C7.64999 7.89 6.99999 8.5 6.99999 9.71C6.99999 10.93 7.88999 12.1 7.99999 12.27C8.13999 12.44 9.75999 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.76999 11.64 9.61999 11.39C9.49999 11.15 9.60999 11 9.72999 10.89C9.83999 10.78 9.99999 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.76999 8.26 9.55999 7.77C9.35999 7.29 9.15999 7.35 8.99999 7.34C8.85999 7.34 8.69999 7.33 8.52999 7.33Z"
      fill="#53BB01"
    />
  </svg>
</template>

<script>
export default {
  name: "WhatsappOutlineIcon",
};
</script>

<style scoped></style>
