<template>
  <v-card-text>
    <h2 class="c13" id="h.1a8vnt4e3ot4"><span class="c9">DISCLAIMER</span></h2>
    <p class="c2">
      <span>Last updated: </span><span class="c14">Apr 21, 2023</span>
    </p>
    <p class="c2"><span class="c0">WEBSITE DISCLAIMER</span></p>
    <p class="c2">
      <span>The information provided by </span
      ><span class="c10">TEOXOFT.COM LLC</span
      ><span
        >&nbsp;(&ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;,
        &ldquo;us&rdquo;) on </span
      ><span class="c10">{{ domain }}</span
      ><span class="c4"
        >&nbsp;(the &ldquo;Site&rdquo;) is for general informational purposes
        only. All information on the Site is provided in good faith, however we
        make no representation or warranty of any kind, express or implied,
        regarding the accuracy, adequacy, validity, reliability, availability,
        or completeness of any information on the Site.</span
      >
    </p>
    <p class="c2">
      <span class="c4"
        >UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS
        OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR
        RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE
        AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN
        RISK.</span
      >
    </p>
    <p class="c2"><span class="c0">EXTERNAL LINKS DISCLAIMER</span></p>
    <p class="c2">
      <span class="c4"
        >The Site may contain (or you may be sent through the Site) links to
        other websites or content belonging to or originating from third parties
        or links to websites and features. Such external links are not
        investigated, monitored, or checked for accuracy, adequacy, validity,
        reliability, availability or completeness by us.</span
      >
    </p>
    <p class="c2">
      <span>For example, the outlined</span
      ><span
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io/disclaimer/&amp;sa=D&amp;source=editors&amp;ust=1682372865992756&amp;usg=AOvVaw0So4rdtm2wSuaa2l8rU2pX"
          >&nbsp;</a
        ></span
      ><span class="c8"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io/disclaimer/&amp;sa=D&amp;source=editors&amp;ust=1682372865993168&amp;usg=AOvVaw0f6cPMIEWuvJmTuASnHv5m"
          >Disclaimer</a
        ></span
      ><span>&nbsp;has been created using</span
      ><span
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io/&amp;sa=D&amp;source=editors&amp;ust=1682372865993476&amp;usg=AOvVaw0JCLdFnk-L6mWoMwgCtIZ6"
          >&nbsp;</a
        ></span
      ><span class="c8"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io/&amp;sa=D&amp;source=editors&amp;ust=1682372865993729&amp;usg=AOvVaw3sOhSOScjGnJZFFgVsdKS4"
          >PolicyMaker.io</a
        ></span
      ><span
        >, a free web application for generating high-quality legal documents.
        PolicyMaker&rsquo;s</span
      ><span
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io/disclaimer/&amp;sa=D&amp;source=editors&amp;ust=1682372865994029&amp;usg=AOvVaw3XtObvuR688zVOr5UrNgPT"
          >&nbsp;</a
        ></span
      ><span class="c8"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io/disclaimer/&amp;sa=D&amp;source=editors&amp;ust=1682372865994333&amp;usg=AOvVaw2ygIXKyNt48jqKo7CIEvY4"
          >disclaimer generator</a
        ></span
      ><span class="c4"
        >&nbsp;is an easy-to-use tool for creating an excellent sample
        Disclaimer template for a website, blog, eCommerce store or app.</span
      >
    </p>
    <p class="c2">
      <span class="c4"
        >WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE
        ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY
        WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY
        BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
        RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
        PROVIDERS OF PRODUCTS OR SERVICES.</span
      >
    </p>
    <p class="c2"><span class="c0">PROFESSIONAL DISCLAIMER</span></p>
    <p class="c2">
      <span class="c4"
        >The Site can not and does not contain legal advice. The information is
        provided for general informational and educational purposes only and is
        not a substitute for professional legal advice. Accordingly, before
        taking any actions based upon such information, we encourage you to
        consult with the appropriate professionals. We do not provide any kind
        of legal advice.</span
      >
    </p>
    <p class="c2">
      <span class="c4"
        >Content published on {{ domain }} is intended to be used and must be
        used for informational purposes only. It is very important to do your
        own analysis before making any decision based on your own personal
        circumstances. You should take independent legal advice from a
        professional or independently research and verify any information that
        you find on our Website and wish to rely upon.</span
      >
    </p>
    <p class="c2">
      <span class="c4"
        >THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THIS SITE IS SOLELY
        AT YOUR OWN RISK.</span
      >
    </p>
    <p class="c2"><span class="c0">AFFILIATES DISCLAIMER</span></p>
    <p class="c2">
      <span class="c4"
        >The Site may contain links to affiliate websites, and we may receive an
        affiliate commission for any purchases or actions made by you on the
        affiliate websites using such links.</span
      >
    </p>
    <p class="c2"><span class="c0">TESTIMONIALS DISCLAIMER</span></p>
    <p class="c2">
      <span class="c4"
        >The Site may contain testimonials by users of our products and/or
        services. These testimonials reflect the real-life experiences and
        opinions of such users. However, the experiences are personal to those
        particular users, and may not necessarily be representative of all users
        of our products and/or services. We do not claim, and you should not
        assume that all users will have the same experiences.</span
      >
    </p>
    <p class="c2"><span class="c4">YOUR INDIVIDUAL RESULTS MAY VARY.</span></p>
    <p class="c2">
      <span class="c4"
        >The testimonials on the Site are submitted in various forms such as
        text, audio and/or video, and are reviewed by us before being posted.
        They appear on the Site verbatim as given by the users, except for the
        correction of grammar or typing errors. Some testimonials may have been
        shortened for the sake of brevity, where the full testimonial contained
        extraneous information not relevant to the general public.</span
      >
    </p>
    <p class="c2">
      <span class="c4"
        >The views and opinions contained in the testimonials belong solely to
        the individual user and do not reflect our views and opinions.</span
      >
    </p>
    <p class="c2"><span class="c0">ERRORS AND OMISSIONS DISCLAIMER</span></p>
    <p class="c2">
      <span class="c4"
        >While we have made every attempt to ensure that the information
        contained in this site has been obtained from reliable sources,
        TEOXOFT.COM LLC is not responsible for any errors or omissions or for
        the results obtained from the use of this information. All information
        in this site is provided &ldquo;as is&rdquo;, with no guarantee of
        completeness, accuracy, timeliness or of the results obtained from the
        use of this information, and without warranty of any kind, express or
        implied, including, but not limited to warranties of performance,
        merchantability, and fitness for a particular purpose.</span
      >
    </p>
    <p class="c2">
      <span class="c4"
        >In no event will TEOXOFT.COM LLC, its related partnerships or
        corporations, or the partners, agents or employees thereof be liable to
        you or anyone else for any decision made or action taken in reliance on
        the information in this Site or for any consequential, special or
        similar damages, even if advised of the possibility of such
        damages.</span
      >
    </p>
    <p class="c2"><span class="c0">GUEST CONTRIBUTORS DISCLAIMER</span></p>
    <p class="c2">
      <span class="c4"
        >This Site may include content from guest contributors and any views or
        opinions expressed in such posts are personal and do not represent those
        of TEOXOFT.COM LLC or any of its staff or affiliates unless explicitly
        stated.</span
      >
    </p>
    <p class="c2"><span class="c0">LOGOS AND TRADEMARKS DISCLAIMER</span></p>
    <p class="c2">
      <span class="c4"
        >All logos and trademarks of third parties referenced on
        {{ domain }} are the trademarks and logos of their respective owners.
        Any inclusion of such trademarks or logos does not imply or constitute
        any approval, endorsement or sponsorship of TEOXOFT.COM LLC by such
        owners.</span
      >
    </p>
    <p class="c2"><span class="c0">CONTACT US</span></p>
    <p class="c2">
      <span
        >Should you have any feedback, comments, requests for technical support
        or other inquiries, please contact us by email: </span
      ><span class="c10">contact@{{ domain }}</span
      ><span class="c4">.</span>
    </p>
    <p class="c6">
      <span class="c5">This</span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io/disclaimer/&amp;sa=D&amp;source=editors&amp;ust=1682372865997621&amp;usg=AOvVaw1_gy7v2ww1Yv_OT02oLBHl"
          >&nbsp;</a
        ></span
      ><span class="c3"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io/disclaimer/&amp;sa=D&amp;source=editors&amp;ust=1682372865997890&amp;usg=AOvVaw3yKx_pZ1qJk-f28pfC0Dwb"
          >Disclaimer</a
        ></span
      ><span class="c5">&nbsp;was created for </span
      ><span class="c5 c10">{{ domain }}</span
      ><span class="c5">&nbsp;by</span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io&amp;sa=D&amp;source=editors&amp;ust=1682372865998275&amp;usg=AOvVaw3LU_nLyxG5PO-ftZ8ZhNQK"
          >&nbsp;</a
        ></span
      ><span class="c3"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://policymaker.io&amp;sa=D&amp;source=editors&amp;ust=1682372865998521&amp;usg=AOvVaw1oS1B_435eWjzt0c18QxGy"
          >PolicyMaker.io</a
        ></span
      ><span class="c5 c11">&nbsp;on 2023-04-21.</span>
    </p>
    <p class="c7"><span class="c4"></span></p>
  </v-card-text>
</template>

<script>
export default {
  name: "LegalDisclaimer",
  computed: {
    domain() {
      return process.env.VUE_APP_DOMAIN;
    },
  },
};
</script>

<style scoped>
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c0 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c9 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 17pt;
  font-family: "Arial";
  font-style: normal;
}
.c4 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c11 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}
.c13 {
  padding-top: 18pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c6 {
  padding-top: 42pt;
  padding-bottom: 8pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c2 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c3 {
  text-decoration-skip-ink: none;
  font-size: 7.5pt;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c8 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c12 {
  background-color: #ffffff;
  padding: 72pt 72pt 72pt 72pt;
}
.c14 {
  color: #595959;
  font-size: 10.5pt;
}
.c1 {
  color: inherit;
  text-decoration: inherit;
}
.c5 {
  font-size: 7.5pt;
}
.c10 {
  font-weight: 700;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
