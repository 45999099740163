<template>
  <div v-if="isVisible" class="photo-viewer_wrapper">
    <div class="photo-viewer_header">
      <div>
        <v-btn
          @click="handleClickClose"
          color="#3D3D3DCC"
          elevation="0"
          width="36px"
          min-width="36px"
        >
          <v-icon color="#fff">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="photo-viewer_body">
      <v-btn
        @click="handleClickChangeImage(-1)"
        color="#3D3D3DCC"
        elevation="0"
        width="36px"
        min-width="36px"
        class="photo-viewer_body-button photo-viewer_body-button-prev"
      >
        <v-icon color="#fff">mdi-chevron-left</v-icon>
      </v-btn>
      <div class="photo-viewer_body-image">
        <v-img
          ref="currentImage"
          height="100%"
          contain
          :src="currentImage"
          class="mx-4 photo-viewer_body-image-current"
          aspect-ratio="0"
          transition="fade-transition"
          v-touch="{
            left: () => handleClickChangeImage(1),
            right: () => handleClickChangeImage(-1),
          }"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="#fecc00"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-img
          ref="prevImage"
          height="100%"
          contain
          :src="prevImage"
          class="mx-4 photo-viewer_body-image-prev"
          aspect-ratio="0"
          transition="fade-transition"
          v-touch="{
            left: () => handleClickChangeImage(1),
            right: () => handleClickChangeImage(-1),
          }"
        ></v-img>
      </div>
      <v-btn
        @click="handleClickChangeImage(1)"
        color="#3D3D3DCC"
        elevation="0"
        width="36px"
        min-width="36px"
        class="photo-viewer_body-button photo-viewer_body-button-next"
      >
        <v-icon color="#fff">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <div class="photo-viewer_footer">
      <div class="photo-viewer_footer-desktop">
        {{ totalInfo }}
      </div>
      <div ref="photoScrollContainer" class="photo-viewer_footer-mobile">
        <v-img
          v-for="(img, i) in computedImages"
          :key="i"
          :src="img.src"
          height="70px"
          width="88px"
          class="photo-viewer_footer-image"
          :class="img.isActive ? 'photo-viewer_image-active' : ''"
          @click="handleClickSetImage(i)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="#fecc00"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main";

export default {
  name: "PhotoViewer",
  data: () => {
    return {
      currentIndex: 0,
      prevIndex: 0,
      isVisible: false,
      images: [],
      selectedImageIndex: 0,
      currentLocation: null,
    };
  },
  methods: {
    handleClickClose() {
      this.isVisible = false;
    },
    handleClickChangeImage(value) {
      this.prevIndex = this.currentIndex;
      if (this.currentIndex + value === this.images.length) {
        this.currentIndex = 0;
      } else if (this.currentIndex + value === -1) {
        this.currentIndex = this.images.length - 1;
      } else {
        this.currentIndex += value;
      }

      //add fade transition
      this.$refs.currentImage.$el.classList.add("fade");
      this.$refs.prevImage.$el.classList.add("fade");

      //scroll element
      this.scrollToTargetImage(this.$refs.photoScrollContainer);

      //remove transition
      setTimeout(() => {
        this.$refs.currentImage.$el.classList.remove("fade");
        this.$refs.prevImage.$el.classList.remove("fade");
      }, 500);
    },
    scrollToTargetImage(targerContainer) {
      setTimeout(() => {
        const container =
          targerContainer ||
          document.querySelector(".photo-viewer_footer-mobile");
        const targetElement = container.querySelector(
          ".photo-viewer_image-active"
        );

        const targetOffsetLeft = targetElement.offsetLeft;

        const scrollAmount =
          targetOffsetLeft -
          (container.clientWidth - targetElement.clientWidth) / 2;

        container.scrollTo({
          left: scrollAmount,
          behavior: "smooth",
        });
      }, 50);
    },
    handleClickSetImage(value) {
      this.currentIndex = value;
    },
    handleKeyDown(e) {
      if (!this.isVisible) return;
      if (e.key === "ArrowLeft") {
        this.handleClickChangeImage(-1);
      }
      if (e.key === "ArrowRight") {
        this.handleClickChangeImage(1);
      }
      if (e.key === "Escape") {
        this.handleClickClose();
      }
    },
    handlePopstateChange() {
      this.handleClickClose();
      console.log(this.currentLocation);
      history.pushState(null, null, this.currentLocation);
    },
  },
  mounted() {
    eventBus.$on("showImageViewer", ({ images, selectedImageIndex }) => {
      this.images = images;
      this.currentIndex = selectedImageIndex;
      this.isVisible = true;
      this.scrollToTargetImage();
    });
  },
  computed: {
    totalInfo() {
      return `${this.currentIndex + 1} из ${this.images.length}`;
    },
    currentImage() {
      return this.images[this.currentIndex];
    },
    prevImage() {
      return this.images[this.prevIndex];
    },
    computedImages() {
      return this.images.map((img, i) => ({
        src: img,
        isActive: this.currentIndex === i,
      }));
    },
  },
  watch: {
    isVisible(val) {
      this.currentLocation = document.location.href;
      if (val) {
        window.addEventListener("popstate", this.handlePopstateChange);
        document.addEventListener("keydown", this.handleKeyDown);
      } else {
        window.removeEventListener("popstate", this.handlePopstateChange);
        document.removeEventListener("keydown", this.handleKeyDown);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-viewer_wrapper {
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100dvh;
  z-index: 99999;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: rgba(0, 0, 0, 0.7);

  @media (max-width: 768px) {
    padding: 16px;
    align-items: baseline;

    .photo-viewer_header-title {
      display: none;
    }

    .photo-viewer_body-image {
      margin: 0 !important;
    }

    .photo-viewer_body-image,
    .photo-viewer_footer-image {
      border-radius: 8px;
    }
    .photo-viewer_footer-image {
      margin-right: 8px;
    }

    .photo-viewer_body-button {
      position: absolute;
      z-index: 3;
    }

    .photo-viewer_body-button-prev {
      left: 20px;
    }
    .photo-viewer_body-button-next {
      right: 20px;
    }
  }
}
.photo-viewer_header {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 0 0 36px;
  justify-content: right;
}
.photo-viewer_body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1 1 auto;
}
.photo-viewer_body-button {
  flex: 0 0 auto;
}
.photo-viewer_body-image {
  flex: 1 1 auto;
  height: 100%;
  position: relative;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.5s;
  }

  .photo-viewer_body-image-current {
    z-index: 1;
  }
  .photo-viewer_body-image-prev {
    visibility: hidden;
    z-index: 2;
    opacity: 1;

    &.fade {
      visibility: visible;
      opacity: 0;
    }
  }
}
.photo-viewer_footer-desktop {
  text-align: center;
  @media (max-width: 768px) {
    display: none;
  }
}
.photo-viewer_footer-mobile {
  display: none;
  @media (max-width: 768px) {
    display: flex;
    overflow: auto;
  }
}
.photo-viewer_footer {
  width: 100%;
  flex: 0 0 36px;
}
.photo-viewer_image-active {
  outline: 2px solid #fecc00;
  outline-offset: -2px;
}
</style>
