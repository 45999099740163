<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18.4733 6.12363C16.0987 7.04925 5.91677 11.0185 3.1035 12.1003C1.21679 12.7933 2.32129 13.443 2.32129 13.443C2.32129 13.443 3.93183 13.9626 5.31251 14.3524C6.69299 14.7422 7.42926 14.3092 7.42926 14.3092L13.9177 10.1948C16.2186 8.72229 15.6664 9.93488 15.1141 10.4547C13.9177 11.5809 11.9389 13.3563 10.2823 14.7856C9.54602 15.3919 9.91406 15.9116 10.2364 16.1715C11.4327 17.1244 14.7001 19.0733 14.884 19.2032C15.8561 19.8509 17.7683 20.7832 18.0592 18.8134L19.2096 12.0139C19.5778 9.71856 19.9459 7.59633 19.9918 6.99003C20.13 5.51734 18.4733 6.12363 18.4733 6.12363Z"
      fill="#1B92D1"
    />
  </svg>
</template>

<script>
export default {
  name: "TelegramMessageIcon",
};
</script>

<style scoped></style>
