<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.9089 18.5908L8.72656 12.4085L14.9089 6.22614"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowRightIcon",
};
</script>

<style scoped>
svg {
  transform: rotate(180deg);
}
</style>
