<template>
  <v-dialog
    v-model="isAdVisible"
    width="80%"
    transition="dialog-bottom-transition"
    content-class="full-screen-ads_ads-dialog"
  >
    <v-card height="100%">
      <v-progress-linear
        v-model="adsProgress"
        color="amber"
      ></v-progress-linear>
      <v-toolbar elevation="0">
        <v-spacer></v-spacer>
        <v-btn icon @click="isAdVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="full-screen-ads_ads-section">
        <Adsense
          root-class="full-screen-ads_ads-wrapper"
          ins-class="full-screen-ads_ads"
          data-ad-client="ca-pub-9595463807816613"
          data-ad-slot="6205529358"
          data-full-width-responsive="yes"
          is-new-ads-code="yes"
          data-ad-format="autorelaxed"
        ></Adsense>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FullScreenAds",
  data: () => ({
    isAdVisible: false,
    adsProgress: 0,
    adTimer: null,
  }),
  mounted() {
    if (process.env.VUE_APP_IS_FULLSCREEN_ADS_ENABLED === "true") {
      this.isAdVisible = true;
      this.adTimer = setInterval(() => {
        this.adsProgress += 20;
        if (this.adsProgress > 100) {
          clearInterval(this.adTimer);
          this.isAdVisible = false;
        }
      }, 1000);
    }
  },
};
</script>

<style>
.full-screen-ads_ads-dialog {
  height: 85%;
}
.full-screen-ads_ads-section,
.full-screen-ads_ads-wrapper,
.full-screen-ads_ads {
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  display: flex !important;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  margin: 0 !important;
}
</style>
