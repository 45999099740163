<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2459 5.7619C13.7935 5.62128 14.2339 5.39862 14.5672 5.09394C14.8767 4.76581 15.1029 4.33222 15.2457 3.79317C15.3681 3.39425 15.4283 2.97939 15.4243 2.56271L15.3886 2.03537L14.8529 2.00021C14.4296 1.99597 14.0082 2.05524 13.603 2.17599C13.0555 2.31662 12.615 2.53927 12.2817 2.84396C11.8294 3.28926 11.5556 3.93379 11.4604 4.77753C11.4127 5.15106 11.4127 5.529 11.4604 5.90252L11.996 5.93768C12.4193 5.94192 12.8408 5.88265 13.2459 5.7619ZM18.8122 8.01182C18.2189 7.19151 17.458 6.69933 16.5295 6.53527C15.9589 6.44152 15.1744 6.52355 14.1762 6.78136C13.3208 7.01573 12.5955 7.29698 12.0004 7.6251C11.4068 7.29698 10.6816 7.01573 9.82451 6.78136C8.82651 6.52355 8.04183 6.44152 7.47046 6.53527C6.54388 6.69933 5.78301 7.19151 5.18783 8.01182C4.66407 8.73838 4.30697 9.65243 4.11651 10.754C3.94997 11.7562 3.96205 12.7793 4.15222 13.7774C4.43648 15.4414 5.00713 16.836 5.86419 17.961C6.88671 19.3203 8.16991 20 9.7138 20C10.0947 20 10.5113 19.8828 10.9637 19.6484C11.2765 19.4642 11.6342 19.3669 11.9988 19.3669C12.3633 19.3669 12.721 19.4642 13.0338 19.6484C13.4862 19.8828 13.9028 20 14.2837 20C15.8281 20 17.1121 19.3203 18.1358 17.961C18.9914 16.836 19.5621 15.4414 19.8478 13.7774C20.0379 12.7793 20.05 11.7562 19.8835 10.754C19.693 9.65243 19.3359 8.73838 18.8122 8.01182Z"
      fill="#53BB01"
    />
  </svg>
</template>

<script>
export default {
  name: "MarketsIcon",
};
</script>

<style scoped></style>
