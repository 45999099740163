<template>
  <v-menu
    v-model="isProfileMenuOpen"
    offset-y
    bottom
    right
    :close-on-content-click="false"
    z-index="99999"
    nudge-bottom="20"
    content-class="profile-menu-wrapper"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        fab
        small
        depressed
        active-class="app-bar_link-active"
        color="#616161"
      >
        <v-icon color="#8C8C8C">mdi-account-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="profile-menu">
        <div class="profile-menu__title">
          <span class="profile-menu__title-text">{{ appName }}</span>
          <!--          <v-icon class="profile-menu__logo">$textLogo</v-icon>-->

          <v-btn
            small
            depressed
            class="profile-menu__close-button"
            @click="handleCloseProfileMenuClick"
          >
            <v-icon color="#B4B4B4">mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="profile-menu__actions">
          <v-btn
            class="profile-menu__action-button"
            depressed
            plain
            x-large
            @click="
              handleLegalClicked({
                tr: 'contact_us',
              })
            "
          >
            <v-icon class="mr-3" color="#B4B4B4"
              >mdi-message-text-outline</v-icon
            >
            <span>{{ $t("map.contact_us") }}</span>
          </v-btn>

          <v-btn
            class="profile-menu__action-button"
            depressed
            plain
            x-large
            href="https://t.me/turnintolocal"
            target="_blank"
          >
            <v-icon class="mr-3" color="#B4B4B4">$telegramMessage</v-icon>
            <span>{{ $t("map.telegram_channel") }}</span>
          </v-btn>
        </div>

        <v-btn
          depressed
          color="#fecc00"
          block
          large
          class="profile-menu__enter-button"
          @click="handleClickLogin"
          >{{ $t("profile.log_in") }}</v-btn
        >
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import i18n from "@/i18n";

export default {
  name: "ProfileMenu",
  data() {
    return {
      isProfileMenuOpen: false,
    };
  },
  methods: {
    handleCloseProfileMenuClick() {
      this.isProfileMenuOpen = false;
    },
    handleClickLogin() {
      this.handleCloseProfileMenuClick();
      if (this.$route.name !== "auth") {
        this.$router.push({ name: "auth", params: { lang: i18n.locale } });
      }
    },
    handleLegalClicked(link) {
      this.handleCloseProfileMenuClick();
      this.$emit("handleLegalClicked", link);
    },
  },
  computed: {
    appName() {
      return process.env.VUE_APP_NAME;
    },
  },
  mounted() {
    window.addEventListener("wheel", this.handleCloseProfileMenuClick);
    window.addEventListener("touchmove", this.handleCloseProfileMenuClick);
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleCloseProfileMenuClick);
    window.removeEventListener("touchmove", this.handleCloseProfileMenuClick);
  },
};
</script>

<style lang="scss">
.profile-menu-wrapper {
  max-width: 100% !important;
}

.profile-menu {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 24px;
  padding-bottom: 16px;
  min-width: 320px;

  @media (max-width: 425px) {
    padding: 16px;
    min-width: 280px;
  }

  &__logo {
    svg {
      width: auto;
      height: 16px;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &-text {
      color: #fecc00;
      font-weight: 700;
      font-family: Poppins, sans-serif;
    }
  }

  &__full-name {
    color: #3d3d3d;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
  }

  &__close-button {
    padding-inline: 0 !important;
    min-width: 30px !important;
  }

  &__enter-button {
    margin-top: 24px;
    border-radius: 8px !important;
    color: #3d3d3d !important;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 24px;
    text-transform: initial;
    letter-spacing: 0;
  }

  &__section {
    padding: 16px 0;
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__action-button {
    justify-content: flex-start !important;
    text-transform: initial !important;
    color: #3d3d3d !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    padding-inline: 8px !important;
    margin-inline: -8px !important;
    transition: background 0.25s ease;

    &:hover {
      background-color: #eaeaea;

      .v-icon {
        color: #fbad00 !important;
      }
    }

    .v-btn__content {
      opacity: 1 !important;
    }
  }
}
</style>
