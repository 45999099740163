<template>
  <v-menu
    :offset-y="small"
    :top="dropTop"
    :bottom="dropBottom"
    left
    z-index="99999"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :block="!small" text v-bind="attrs" v-on="on" color="#8C8C8C">
        <div class="language-menu_button-content">
          <div class="d-flex align-center">
            <i :class="languageOptions[appliedLocale].flag"></i>
            <span
              v-show="!small"
              class="ml-2 language-menu_button-content-text"
            >
              {{ languageOptions[appliedLocale].text }}
            </span>
          </div>
          <v-icon v-if="small" right dark class="ml-1"> $downArrow </v-icon>
          <v-icon v-if="!small" right dark class="ml-1 language-menu_icon">
            $arrowRight
          </v-icon>
        </div>
      </v-btn>
    </template>

    <v-list nav :dark="small" :color="small ? 'primary' : '#ededed'">
      <v-list-item-group
        mandatory
        v-model="appliedLocale"
        :color="small ? 'var(--color-secondary)' : 'primary'"
      >
        <v-list-item
          v-for="item in languageOptions"
          :key="item.title"
          @click="handleChangeLocale(item.title)"
        >
          <v-list-item-content>
            <span
              v-if="small"
              class="d-flex align-center text-uppercase language-menu_list-item-text"
            >
              <i :class="item.flag" class="mr-2"></i>
              {{ item.title }}
            </span>
            <span v-if="!small" class="d-flex align-center">
              <i :class="item.flag" class="mr-2"></i> {{ item.text }}
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import i18n from "@/i18n";
import { setApiLanguage } from "@/api";

export default {
  name: "LanguageMenu",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    dropBottom: {
      type: Boolean,
      default: false,
    },
    dropTop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    languageOptions: [
      { title: "ru", text: "Русский", flag: "em em-ru" },
      { title: "en", text: "English", flag: "em em-us" },
      { title: "tr", text: "Türkiye", flag: "em em-flag-tr" },
    ],
    appliedLocale: 0,
    appliedSubItem: 0,
  }),

  mounted() {
    this.appliedLocale = this.languageOptions.findIndex(
      (l) => l.title === i18n.locale
    );
  },

  methods: {
    handleChangeLocale(locale) {
      if (i18n.locale !== locale) {
        setApiLanguage(locale);
        this.$route.params.lang = locale;
        const localeURL = this.$router.resolve({
          name: this.$route.name,
          params: this.$route.params,
          query: this.$route.query,
        });
        window.location.assign(localeURL.href);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.language-menu_button-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-text {
    color: #3d3d3d;
    text-transform: none;
    letter-spacing: normal;
  }
}
.language-menu_list-item-text {
  font-size: 13px;
  line-height: 13px;
}
::v-deep .language-menu_icon {
  svg path {
    stroke: #8c8c8c;
  }
}
</style>
