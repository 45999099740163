<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_296_11168)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 22.5C0 23.3284 0.671573 24 1.5 24H16.5H22.5H24V22.5V16.5C24 16.5 23.7426 15 22.5 15C21.2574 15 21 16.5 21 16.5V21H16.5H3V7.5V3H7.5C7.5 3 9 3 9 1.5C9 0 7.5 0 7.5 0H1.5H0V1.5V7.5V22.5ZM15 13.5H12.6212L21 5.12118V7.5C21 7.5 21 9 22.5 9C24 9 24 7.5 24 7.5V0H16.5C16.5 0 15 0.257359 15 1.5C15 2.74264 16.5 3 16.5 3H18.8785L10.5 11.3785V9C10.5 9 10.5 7.5 9 7.5C7.5 7.5 7.5 9 7.5 9V16.5H15C15 16.5 16.5 16.2426 16.5 15C16.5 13.7574 15 13.5 15 13.5Z"
        fill="#D7D7D7"
      />
    </g>
    <defs>
      <clipPath id="clip0_296_11168">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ApartmentAreaIcon",
};
</script>

<style scoped></style>
