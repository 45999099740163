<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="white" />
    <mask
      id="mask0_2548_27388"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="18"
      height="18"
    >
      <path
        d="M12 3C7.02823 3 3 7.02823 3 12C3 16.9718 7.02823 21 12 21C16.9718 21 21 16.9718 21 12C21 7.02823 16.9718 3 12 3ZM12 19.2581C7.98992 19.2581 4.74194 16.0101 4.74194 12C4.74194 7.98992 7.98992 4.74194 12 4.74194C16.0101 4.74194 19.2581 7.98992 19.2581 12C19.2581 16.0101 16.0101 19.2581 12 19.2581ZM14.2427 15.4694L11.1617 13.2302C11.0492 13.1468 10.9839 13.0161 10.9839 12.8782V6.91935C10.9839 6.67984 11.1798 6.48387 11.4194 6.48387H12.5806C12.8202 6.48387 13.0161 6.67984 13.0161 6.91935V12.0617L15.4403 13.8254C15.6363 13.9669 15.6762 14.2391 15.5347 14.4351L14.8524 15.375C14.7109 15.5673 14.4387 15.6109 14.2427 15.4694Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_2548_27388)">
      <rect
        x="24"
        width="24"
        height="24"
        transform="rotate(90 24 0)"
        fill="#FF4C5B"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "DutyPharmacyIcon",
};
</script>

<style scoped></style>
