<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0989 10.8224C9.33594 12.3198 9.33594 14.28 9.33594 18.2004V42.467C9.33594 46.3874 9.33594 48.3476 10.0989 49.845C10.77 51.1621 11.8409 52.233 13.158 52.9041C14.6554 53.667 16.6156 53.667 20.5359 53.667H35.4693C39.3896 53.667 41.3498 53.667 42.8472 52.9041C44.1643 52.233 45.2352 51.1621 45.9063 49.845C46.6693 48.3476 46.6693 46.3874 46.6693 42.467V18.2004C46.6693 14.28 46.6693 12.3198 45.9063 10.8224C45.2352 9.5053 44.1643 8.43443 42.8472 7.76332C41.3498 7.00037 39.3896 7.00037 35.4693 7.00037H20.5359C16.6156 7.00037 14.6554 7.00037 13.158 7.76332C11.8409 8.43443 10.77 9.5053 10.0989 10.8224ZM17.2932 39.0839H39.1572C39.767 39.0839 40.2609 39.5785 40.261 40.1876C40.261 40.7973 39.767 41.2914 39.1572 41.2914H17.2932C16.6835 41.2914 16.1895 40.7973 16.1895 40.1876C16.1895 39.5785 16.6835 39.0839 17.2932 39.0839ZM34.1893 44.5077H22.2629C21.6537 44.5077 21.1592 45.0022 21.1592 45.6113C21.1592 46.2211 21.6537 46.7151 22.2629 46.7151H34.1893C34.799 46.7151 35.2929 46.2211 35.2929 45.6113C35.2929 45.0022 34.799 44.5077 34.1893 44.5077ZM36.2041 24.6191L32.9797 25.6666L36.2041 26.7142V30.105L38.1974 27.3618L41.422 28.4094L39.4291 25.6666L41.422 22.9239L38.1974 23.9715L36.2041 21.2283V24.6191ZM28.976 34.3942C30.8669 33.6776 32.474 32.3663 33.5553 30.6575C32.5455 31.7811 31.2187 32.572 29.75 32.9257C28.2813 33.2795 26.7398 33.1794 25.3292 32.6387C23.9186 32.098 22.7052 31.1421 21.8492 29.8973C20.9932 28.6525 20.535 27.1773 20.535 25.6666C20.535 24.156 20.9932 22.6808 21.8492 21.436C22.7052 20.1912 23.9186 19.2353 25.3292 18.6946C26.7398 18.1539 28.2813 18.0538 29.75 18.4075C31.2187 18.7613 32.5455 19.5522 33.5553 20.6758C32.474 18.967 30.8669 17.6557 28.976 16.939C27.0851 16.2224 25.0125 16.1393 23.0703 16.7022C21.1281 17.2651 19.4212 18.4436 18.2065 20.0603C16.9918 21.677 16.3351 23.6445 16.3351 25.6666C16.3351 27.6888 16.9918 29.6563 18.2065 31.273C19.4212 32.8897 21.1281 34.0682 23.0703 34.6311C25.0125 35.194 27.0851 35.1109 28.976 34.3942Z"
      fill="#0062FF"
    />
  </svg>
</template>

<script>
export default {
  name: "PassportIcon",
};
</script>

<style scoped></style>
