<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7142 16.0146L10.2074 15.2796H9.31465H6C5.28203 15.2796 4.7 14.6976 4.7 13.9796V7C4.7 6.28203 5.28203 5.7 6 5.7H18C18.718 5.7 19.3 6.28203 19.3 7V13.9796C19.3 14.6976 18.718 15.2796 18 15.2796H14.2373H13.2535L12.7634 16.1326L11.8689 17.6892L10.7142 16.0146ZM6 4C4.34315 4 3 5.34315 3 7V13.9796C3 15.6365 4.34315 16.9796 6 16.9796H9.31465L11.0996 19.5681C11.5171 20.1735 12.4234 20.1363 12.7898 19.4986L14.2373 16.9796H18C19.6569 16.9796 21 15.6365 21 13.9796V7C21 5.34315 19.6569 4 18 4H6ZM15.6001 7.46124H7.5001C7.00304 7.46124 6.6001 7.84865 6.6001 8.32655C6.6001 8.80445 7.00304 9.19186 7.5001 9.19186H15.6001C16.0972 9.19186 16.5001 8.80445 16.5001 8.32655C16.5001 7.84865 16.0972 7.46124 15.6001 7.46124ZM12.0001 10.9225H7.5001C7.00304 10.9225 6.6001 11.3099 6.6001 11.7878C6.6001 12.2657 7.00304 12.6531 7.5001 12.6531H12.0001C12.4972 12.6531 12.9001 12.2657 12.9001 11.7878C12.9001 11.3099 12.4972 10.9225 12.0001 10.9225Z"
      fill="#B4B4B4"
    />
  </svg>
</template>

<script>
export default {
  name: "MessageIcon",
};
</script>

<style scoped></style>
