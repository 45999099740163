<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 20L20 20V17L4 17V20Z" fill="#8C8C8C" />
    <path d="M6 14L18 14V11L6 11L6 14Z" fill="#B4B4B4" />
    <path d="M9 8L15 8L15 5L9 5L9 8Z" fill="#D7D7D7" />
  </svg>
</template>
<script>
export default {
  name: "SortAscIcon",
};
</script>
<style scoped></style>
