<template>
  <svg
    width="375.000000pt"
    height="324.000000pt"
    viewBox="0 0 375.000000 324.000000"
    preserveAspectRatio="xMidYMid meet"
    id="svg10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs14" />
    <sodipodi:namedview
      id="namedview12"
      pagecolor="#ffffff"
      bordercolor="#000000"
      borderopacity="0.25"
      showgrid="false"
    />
    <g
      transform="translate(0.000000,324.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
      id="g8"
      style="fill: #ba170a; fill-opacity: 1"
    >
      <path
        d="M536 3180 c-2 -8 -21 -94 -41 -190 -20 -96 -39 -182 -41 -191 -4 -13 -39 -19 -202 -34 -108 -10 -200 -21 -205 -24 -4 -4 64 -48 151 -99 86 -50 166 -97 176 -105 18 -13 17 -20 -23 -203 -23 -104 -44 -195 -46 -202 -7 -21 11 -6 162 130 78 70 146 128 151 128 6 0 89 -47 186 -104 97 -58 176 -102 176 -100 0 3 -36 88 -80 189 -44 101 -80 188 -80 192 0 5 69 69 154 143 85 74 153 136 152 137 -1 2 -94 -6 -206 -17 -112 -12 -207 -17 -211 -13 -4 4 -44 91 -88 193 -44 102 -82 178 -85 170z"
        id="path2"
        style="fill: #ba170a; fill-opacity: 1"
      />
      <path
        d="M1980 3015 c79 -45 128 -82 198 -149 52 -49 142 -158 142 -171 0 -3 -19 7 -42 23 -174 119 -427 199 -658 207 -96 4 -116 2 -85 -5 205 -52 366 -143 513 -291 186 -187 286 -415 298 -684 31 -640 -508 -1195 -1161 -1195 -363 0 -684 173 -881 475 -98 151 -145 281 -167 467 l-13 103 -13 -85 c-18 -120 -16 -325 4 -445 33 -192 118 -394 225 -537 73 -96 234 -245 334 -307 250 -156 518 -218 901 -208 242 6 399 33 594 101 336 118 544 265 856 607 39 43 79 75 115 92 130 63 323 74 535 30 l30 -6 -42 46 c-58 63 -90 116 -138 232 -62 148 -92 202 -138 252 -83 90 -180 113 -303 71 l-71 -24 -6 146 c-13 311 -115 584 -300 801 -162 189 -391 349 -629 439 -147 55 -177 60 -98 15z m1277 -1641 c8 -22 -10 -49 -32 -49 -10 0 -22 7 -28 16 -15 24 -1 49 28 49 15 0 28 -7 32 -16z"
        id="path4"
        style="fill: #ba170a; fill-opacity: 1"
      />
      <path
        d="M2955 697 c-314 -327 -757 -542 -1229 -598 -130 -15 -461 -6 -586 16 -369 65 -700 280 -933 605 -15 21 -14 19 2 -15 56 -115 220 -305 348 -401 237 -178 562 -273 939 -274 505 -1 984 188 1336 528 81 78 196 202 186 202 -1 0 -30 -28 -63 -63z"
        id="path6"
        style="fill: #ba170a; fill-opacity: 1"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "GocLogoIcon",
};
</script>
