<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 3V4H1.17383C0.621543 4 0.173828 4.44771 0.173828 5V15C0.173828 15.5523 0.621545 16 1.17383 16H16.8267C17.379 16 17.8267 15.5523 17.8267 15V5C17.8267 4.44772 17.379 4 16.8267 4H13V3C13 1.34315 11.6569 0 10 0H8C6.34315 0 5 1.34315 5 3ZM11 4V3C11 2.44772 10.5523 2 10 2H8C7.44772 2 7 2.44772 7 3V4H11ZM7 6.25C7 6.11193 7.11193 6 7.25 6H10.75C10.8881 6 11 6.11193 11 6.25V7.75C11 7.88807 10.8881 8 10.75 8H7.25C7.11193 8 7 7.88807 7 7.75V6.25Z"
      fill="#FFFFFF"
    />
  </svg>
</template>

<script>
export default {
  name: "BriefcaseIcon",
};
</script>

<style scoped></style>
