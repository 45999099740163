<template>
  <v-avatar
    class="user-profile-avatar"
    color="#fecc00"
    v-on="on"
    v-bind="attrs"
  >
    <img
      v-if="userInfo?.profilePic"
      :src="userInfo?.profilePic"
      :alt="userInfo?.initials"
    />
    <span v-else class="user-profile-avatar__initials">{{
      userInfo?.initials
    }}</span>
  </v-avatar>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    on: {
      type: Object,
    },
    attrs: {
      type: Object,
    },
  },
  computed: {
    userInfo() {
      return !this.$store.getters.isAuthenticated
        ? null
        : {
            initials: `${this.$store.getters.user.first_name[0] || ""}`,
            profilePic: this.$store.getters.user.profile.picture_url || "",
          };
    },
  },
};
</script>

<style scoped lang="scss">
.user-profile-avatar {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;

  &__initials {
    font-size: 20px;
    font-weight: 600;
    color: #3d3d3d;
    text-transform: uppercase;
  }
}
</style>
