<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5606 9.93937L15.0606 4.44084C14.1184 3.49865 12.5 4.16647 12.5 5.50147V8.02344C8.18875 8.15312 3 8.99 3 14.3141C3 16.6187 4.18812 18.524 6.05094 19.75C7.21094 20.5134 8.73344 19.4093 8.30688 18.0481C7.54594 15.6206 7.91906 14.3187 12.5 14.0519V16.4981C12.5 17.8306 14.1166 18.5028 15.0606 17.5587L20.5605 12.0606C21.1465 11.475 21.1465 10.525 20.5606 9.93937ZM14 16.5V12.5028C8.85872 12.5587 5.33 13.5597 6.87562 18.4906C5.75 17.75 4.5 16.4066 4.5 14.3141C4.5 9.869 9.52559 9.52062 14 9.50094V5.5L19.5 11L14 16.5Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      default: "#B4B4B4",
      type: String,
    },
  },
  name: "ShareIcon",
};
</script>

<style scoped></style>
