<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons / yandex">
      <g id="Group 1377">
        <path
          id="Vector"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="#FC3F1D"
        />
        <path
          id="Glyph"
          d="M15.6184 18.2778H13.4231V7.42046H12.4452C10.6527 7.42046 9.71374 8.31673 9.71374 9.65464C9.71374 11.1725 10.3614 11.8758 11.7011 12.7721L12.8052 13.5162L9.63209 18.2759H7.27173L10.1275 14.0265C8.48531 12.8538 7.56121 11.7088 7.56121 9.77711C7.56121 7.36294 9.24427 5.7207 12.4304 5.7207H15.6035V18.2741H15.6184V18.2778Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "YandexIcon",
};
</script>

<style scoped></style>
