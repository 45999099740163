<template>
  <div>
    <v-app-bar
      dark
      style="flex: 0 0 auto"
      class="app-bar_wrapper"
      color="primary"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Teoxoft Logo"
          class="shrink"
          style="cursor: pointer"
          contain
          src="../assets/logo.svg"
          transition="scale-transition"
          height="50"
          width="50"
          @click="
            $router.push({ name: 'home', params: { lang: $i18n.locale } })
          "
        />
      </div>

      <v-toolbar-items class="hidden-sm-and-down">
        <template v-for="item in menuItems">
          <v-menu v-if="item.subItems" :key="item.title" offset-y center bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="toobar__item"
                :color="isCalcRoute ? 'var(--color-secondary)' : ''"
              >
                <v-icon :left="$vuetify.breakpoint.mdAndUp">
                  mdi-{{ item.icon }}
                </v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp">
                  {{ getTitle(item.title) }}
                </span>
              </v-btn>
            </template>

            <v-list nav dark color="primary">
              <v-list-item
                v-for="(subItem, index) in item.subItems"
                :key="subItem.title"
                :to="{
                  name: subItem.name,
                  params: { lang: $route.params.lang },
                }"
                :value="index"
                active-class="app-bar_link-active"
                exact-path
              >
                <v-list-item-avatar>
                  <v-icon> mdi-{{ subItem.icon }} </v-icon>
                </v-list-item-avatar>

                <v-list-item-title>
                  {{ getTitle(subItem.name) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            v-else
            :key="item.title"
            :text="$vuetify.breakpoint.mdAndUp"
            :to="{ name: item.name, params: { lang: $route.params.lang } }"
            :icon="$vuetify.breakpoint.smAndDown"
            active-class="app-bar_link-active"
            exact-path
            class="toobar__item"
          >
            <v-icon :left="$vuetify.breakpoint.mdAndUp">
              mdi-{{ item.icon }}
            </v-icon>
            <span v-if="$vuetify.breakpoint.mdAndUp">
              {{ getTitle(item.title) }}
            </span>
          </v-btn>
        </template>
      </v-toolbar-items>

      <div class="app-bar__current-page" v-if="$vuetify.breakpoint.smAndDown">
        <v-icon color="#fecc00">{{ currentPage.icon }}</v-icon>
        <span>{{ currentPage.text }}</span>
      </div>

      <div class="d-flex align-center" z-index="5">
        <div class="mr-2">
          <LanguageMenu v-if="$vuetify.breakpoint.mdAndUp" small drop-bottom />
        </div>

        <UserMenu
          v-if="isUserAuthenticated"
          @handleLegalClicked="(data) => $emit('handleLegalClicked', data)"
        />
        <ProfileMenu
          v-if="!isUserAuthenticated"
          @handleLegalClicked="(data) => $emit('handleLegalClicked', data)"
        />

        <v-icon
          class="hidden-md-and-up menuIcon ml-4"
          @click="$emit('triggerShowDrawer')"
        >
          mdi-menu
        </v-icon>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import UserMenu from "@/components/User/UserMenu.vue";
import ProfileMenu from "@/components/User/ProfileMenu.vue";
import LanguageMenu from "@/components/Common/LanguageMenu.vue";
import { menuItems } from "@/components/Common/constants";

export default {
  name: "AppBar",

  components: {
    UserMenu,
    LanguageMenu,
    ProfileMenu,
  },

  props: { isShowDrawer: Boolean },

  data() {
    return {
      menuItems,
      appliedSubItem: 0,
      activeItem: 1,
    };
  },

  mounted() {
    if (this.$route.name === "car" || this.$route.name === "calc") {
      this.appliedSubItem = this.menuItems[1].subItems.findIndex(
        (i) => i.name === this.$route.name
      );
    }
  },

  computed: {
    isUserAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    userInfo() {
      return !this.$store.getters.isAuthenticated
        ? null
        : {
            fullName: `${this.$store.getters.user.first_name} ${this.$store.getters.user.last_name}`,
            initials: `${this.$store.getters.user.first_name[0] || ""}${
              this.$store.getters.user.last_name[0] || ""
            }`,
            profilePic:
              !this.$store.getters.isAuthenticated ||
              (this.$store.getters.user.profile.picture_url &&
                this.$store.getters.user.profile.picture_url.length === 0)
                ? null
                : this.$store.getters.user.profile.picture_url,
          };
    },
    isCalcRoute() {
      return this.$route.name === "car" || this.$route.name === "calc";
    },
    currentPage() {
      switch (this.$route.name) {
        case "map":
          return {
            text: this.getTitle("map"),
            icon: "mdi-map",
          };
        case "car":
          return {
            text: this.getTitle("car"),
            icon: "mdi-car",
          };
        case "calc":
          return {
            text: this.getTitle("calc"),
            icon: "mdi-calculator",
          };
        case "faq":
          return {
            text: this.getTitle("faq"),
            icon: "mdi-frequently-asked-questions",
          };
        case "insuranceSearch":
          return {
            text: this.getTitle("insurance"),
            icon: "mdi-shield-check-outline",
          };

        default:
          return {
            text: "",
            icon: "",
          };
      }
    },
  },

  methods: {
    getTitle(title) {
      return this.$t(`app_bar.${String(title).toLowerCase()}`);
    },
  },
};
</script>

<style lang="scss">
.app-bar_wrapper {
  z-index: 10;
}
.v-list-item {
  cursor: pointer;
}
.app-bar_ads-section,
.app-bar_ads-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.app-bar_ads {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
}
.app-bar_link-active {
  color: var(--color-secondary) !important;
  border-bottom: 2px solid var(--color-secondary);
}
.app-bar_link-active:before {
  opacity: 0 !important;
}
.app-bar_wrapper .v-toolbar__content {
  justify-content: space-between;
  padding-right: 32px;

  @media (max-width: 960px) {
    padding-right: 16px;
  }
}

.v-toolbar__items {
  width: 100%;
  display: flex;
  justify-content: center;
}

.v-toolbar__items .toobar__item {
  width: 20vw;
}

.menuIcon::after {
  background-color: unset !important;
}

.app-bar__current-page {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fecc00;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
</style>
