var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible)?_c('div',{staticClass:"photo-viewer_wrapper"},[_c('div',{staticClass:"photo-viewer_header"},[_c('div',[_c('v-btn',{attrs:{"color":"#3D3D3DCC","elevation":"0","width":"36px","min-width":"36px"},on:{"click":_vm.handleClickClose}},[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v("mdi-close")])],1)],1)]),_c('div',{staticClass:"photo-viewer_body"},[_c('v-btn',{staticClass:"photo-viewer_body-button photo-viewer_body-button-prev",attrs:{"color":"#3D3D3DCC","elevation":"0","width":"36px","min-width":"36px"},on:{"click":function($event){return _vm.handleClickChangeImage(-1)}}},[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"photo-viewer_body-image"},[_c('v-img',{directives:[{name:"touch",rawName:"v-touch",value:({
          left: () => _vm.handleClickChangeImage(1),
          right: () => _vm.handleClickChangeImage(-1),
        }),expression:"{\n          left: () => handleClickChangeImage(1),\n          right: () => handleClickChangeImage(-1),\n        }"}],ref:"currentImage",staticClass:"mx-4 photo-viewer_body-image-current",attrs:{"height":"100%","contain":"","src":_vm.currentImage,"aspect-ratio":"0","transition":"fade-transition"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#fecc00"}})],1)]},proxy:true}],null,false,3631271975)}),_c('v-img',{directives:[{name:"touch",rawName:"v-touch",value:({
          left: () => _vm.handleClickChangeImage(1),
          right: () => _vm.handleClickChangeImage(-1),
        }),expression:"{\n          left: () => handleClickChangeImage(1),\n          right: () => handleClickChangeImage(-1),\n        }"}],ref:"prevImage",staticClass:"mx-4 photo-viewer_body-image-prev",attrs:{"height":"100%","contain":"","src":_vm.prevImage,"aspect-ratio":"0","transition":"fade-transition"}})],1),_c('v-btn',{staticClass:"photo-viewer_body-button photo-viewer_body-button-next",attrs:{"color":"#3D3D3DCC","elevation":"0","width":"36px","min-width":"36px"},on:{"click":function($event){return _vm.handleClickChangeImage(1)}}},[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v("mdi-chevron-right")])],1)],1),_c('div',{staticClass:"photo-viewer_footer"},[_c('div',{staticClass:"photo-viewer_footer-desktop"},[_vm._v(" "+_vm._s(_vm.totalInfo)+" ")]),_c('div',{ref:"photoScrollContainer",staticClass:"photo-viewer_footer-mobile"},_vm._l((_vm.computedImages),function(img,i){return _c('v-img',{key:i,staticClass:"photo-viewer_footer-image",class:img.isActive ? 'photo-viewer_image-active' : '',attrs:{"src":img.src,"height":"70px","width":"88px"},on:{"click":function($event){return _vm.handleClickSetImage(i)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#fecc00"}})],1)]},proxy:true}],null,true)})}),1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }