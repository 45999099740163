<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8258 6.22228C11.2653 5.56448 11.4999 4.79113 11.4999 4C11.4999 2.93913 11.0785 1.92172 10.3283 1.17157C9.57816 0.421427 8.56075 0 7.49988 0C6.70875 0 5.9354 0.234596 5.2776 0.674122C4.6198 1.11365 4.10711 1.73836 3.80436 2.46927C3.50161 3.20017 3.4224 4.00444 3.57674 4.78036C3.73108 5.55629 4.11204 6.26902 4.67145 6.82843C5.23086 7.38784 5.94359 7.7688 6.71952 7.92314C7.49544 8.07748 8.29971 7.99827 9.03061 7.69552C9.76152 7.39277 10.3862 6.88008 10.8258 6.22228ZM9.77812 9H10.3C11.4138 9.00025 12.482 9.44283 13.2696 10.2304C14.0572 11.018 14.4998 12.0862 14.5 13.2V14.5C14.5 14.8978 14.342 15.2794 14.0607 15.5607C13.7794 15.842 13.3978 16 13 16H2C1.60218 16 1.22064 15.842 0.93934 15.5607C0.658035 15.2794 0.5 14.8978 0.5 14.5V13.2C0.500249 12.0862 0.942827 11.018 1.73043 10.2304C2.51802 9.44283 3.58617 9.00025 4.7 9H5.22188C5.93618 9.32936 6.71342 9.49992 7.5 9.49992C8.28658 9.49992 9.06382 9.32936 9.77812 9Z"
      fill="#FFFFFF"
    />
  </svg>
</template>

<script>
export default {
  name: "IndividualIcon",
};
</script>

<style scoped></style>
