<template>
  <v-dialog v-model="modelValue" v-if="modelValue" max-width="540">
    <v-card class="post-listing-dialog">
      <v-btn class="close-button" icon color="#b4b4b4" @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-title class="header">
        <v-img
          alt="Teoxoft Logo"
          class="logo"
          contain
          :src="logoSrc"
          height="60"
          width="60"
        />

        <h3 class="title">{{ $t("map.post_listing_modal.post_listing") }}</h3>

        <p class="subtitle">
          {{ $t("map.post_listing_modal.choose_account_type") }}
        </p>
      </v-card-title>

      <v-card-text class="pa-0 mb-6">
        <v-radio-group hide-details v-model="userType">
          <v-radio
            class="user-type-choose-button"
            color="#0062FF"
            value="individual"
          >
            <template v-slot:label>
              <div>
                <div class="title">
                  {{ $t("map.post_listing_modal.individual") }}
                </div>
                <div class="subtitle">
                  {{ $t("map.post_listing_modal.individual_type_text") }}
                </div>
              </div>
            </template></v-radio
          >

          <v-radio
            class="user-type-choose-button"
            color="#0062FF"
            value="real_estate"
          >
            <template v-slot:label>
              <div>
                <div class="title">
                  {{ $t("map.post_listing_modal.company") }}
                </div>
                <div class="subtitle">
                  {{ $t("map.post_listing_modal.company_type_text") }}
                </div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-btn
          depressed
          color="#fecc00"
          block
          large
          @click="onContinueClick"
          :loading="isLoading"
        >
          {{ $t("map.post_listing_modal.continue") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CryptoJS from "crypto-js";
import logoSrc from "@/assets/logo.svg";
import { api } from "@/api";
import { eventBus } from "@/main";
// import {
//   addUTMparamsToURL,
//   addHTTPrefererToURL,
// } from "@/components/Common/helpers/utils";

export default {
  name: "PostListingDialog",
  props: {
    value: Boolean,
  },

  data() {
    return {
      logoSrc,
      userType: "individual",
      isLoading: false,
    };
  },

  watch: {
    modelValue(value) {
      if (!value) {
        this.userType = "individual";
      }
    },
  },

  methods: {
    closeModal() {
      this.modelValue = false;
    },
    encryptToken(token) {
      const encryptionKey = process.env.VUE_APP_ENCRYPTION_KEY;

      return CryptoJS.AES.encrypt(token, encryptionKey).toString();
    },
    async onContinueClick() {
      switch (this.userType) {
        case "individual": {
          if (!this.$store.getters.isAuthenticated) {
            this.$router.push({
              name: "auth",
              params: { lang: this.$i18n.locale },
              query: { partnerRedirect: true },
            });

            this.closeModal();

            return;
          }

          if (this.$store.getters.user.partner_type_code === null) {
            this.$emit("postListingAsIndividual");

            this.closeModal();

            return;
          }

          const encryptedToken = this.encryptToken(
            this.$store.getters.tokens.refresh_token
          );
          const encodedToken = encodeURIComponent(encryptedToken);

          this.isLoading = true;

          await api.post("/partners/register_or_get/");
          const response = await api.get("auth/user");

          if (response) {
            this.$store.dispatch("setUser", response.data);
          }

          this.isLoading = false;

          window
            .open(`${this.partnerPortalUrl}?auth=${encodedToken}`, "_blank")
            ?.focus();

          break;
        }

        case "real_estate": {
          eventBus.$emit("postListingAsCompanyClick");
          this.closeModal();
          this.$gtag.event("conversion", {
            send_to: "AW-16618629707/bqRECJnc--sZEMvUsPQ9",
          });
          // const url = new URL(this.partnerPortalUrl);
          // addUTMparamsToURL(url);
          // addHTTPrefererToURL(url);
          // window.open(url, "_blank")?.focus;

          break;
        }

        default:
          return;
      }

      this.closeModal();
    },
  },

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    partnerPortalUrl() {
      return `${process.env.VUE_APP_PARTNER_PORTAL_URL}/${this.$i18n.locale}`;
    },
  },
};
</script>

<style scoped lang="scss">
.post-listing-dialog {
  position: relative;
  padding: 32px 40px;

  .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
    padding: 0;

    .logo {
      margin-bottom: 16px;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      color: #212121;
      margin-bottom: 8px;
    }

    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: #616161;
      margin-bottom: 0;
    }
  }

  .user-type-choose-button {
    align-items: flex-start;
    margin-bottom: 16px !important;

    .title {
      line-height: 24px;
      font-size: 16px !important;
      font-weight: 600;
      color: #3d3d3d;
      margin-bottom: 4px;
    }

    .subtitle {
      font-size: 16px !important;
      font-weight: 400;
      color: #8c8c8c;
    }
  }
}
</style>
