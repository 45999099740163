<template>
  <v-snackbar
    app
    class="snackbar-global"
    v-model="snackbar"
    :timeout="timeout"
    :color="color"
    :multi-line="multiLine"
    :vertical="vertical"
    :right="right"
    :top="top"
    :bottom="bottom"
    :left="left"
    :absolute="absolute"
    :inverted="inverted"
    :rounded="rounded"
    :transition="transition"
  >
    {{ message }}
    <template v-if="showAction" v-slot:action>
      <v-btn color="info" text @click="actionHandler">
        {{ actionText }}
      </v-btn>
    </template>
    <template v-else v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="snackbar = false"
        class="snackbar-close-button"
      >
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBarGlobal",
  data: () => ({
    snackbar: false,
    message: "",
    timeout: 6000,
    color: "",
    multiLine: false,
    vertical: false,
    right: false,
    top: true,
    bottom: false,
    left: false,
    absolute: false,
    inverted: false,
    rounded: false,
    transition: "v-snack-transition",
    showAction: false,
    actionText: "",
    actionHandler: null,
  }),
  methods: {
    showSnackbar(message, color = "info", timeout = 6000) {
      this.message = message;
      this.color = color;
      this.timeout = timeout;
      this.snackbar = true;
    },

    showSnackbarWithAction(
      message,
      actionText,
      actionHandler,
      color = "info",
      timeout = 6000
    ) {
      this.message = message;
      this.color = color;
      this.timeout = timeout;
      this.showAction = true;
      this.actionText = actionText;
      this.actionHandler = actionHandler;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.snackbar-global {
  z-index: 9999999;
}
.snackbar-close-button {
  min-width: unset !important;
}
</style>
