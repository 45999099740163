<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.65748 2C1.92618 2 1.33335 2.59284 1.33335 3.32414V10.2759C1.33335 10.2759 1.69112 9.16554 3.33329 8.21023V6.08277C3.33329 5.35147 3.92612 4.75863 4.65742 4.75863H10.0091C10.7405 4.75863 11.3333 5.35147 11.3333 6.08277V6.91382C11.5509 6.92821 11.7731 6.94539 12 6.96552C12.2269 6.94539 12.4491 6.92821 12.6667 6.91383V6.08277C12.6667 5.35147 13.2595 4.75863 13.9908 4.75863H19.3425C20.0738 4.75863 20.6667 5.35147 20.6667 6.08277V8.21019C22.3089 9.16551 22.6667 10.2759 22.6667 10.2759V3.32414C22.6667 2.59284 22.0738 2 21.3425 2H2.65748ZM0 15.1034C0 11.2946 3.08769 8.2069 6.89655 8.2069H17.1035C20.9123 8.2069 24 11.2946 24 15.1034H0ZM0 16.4828H24V22H20.6667V19.9034C20.6667 19.5378 20.3702 19.2414 20.0046 19.2414H12H3.9954C3.62975 19.2414 3.33333 19.5378 3.33333 19.9034V22H0V16.4828Z"
      fill="#D7D7D7"
    />
  </svg>
</template>

<script>
export default {
  name: "BedIcon",
};
</script>

<style scoped></style>
