<template>
  <v-menu
    v-model="isUserMenuOpen"
    offset-y
    bottom
    right
    :close-on-content-click="false"
    z-index="99999"
    nudge-bottom="20"
    content-class="user-menu-wrapper"
  >
    <template v-slot:activator="{ on, attrs }">
      <UserAvatar :attrs="attrs" :on="on" />
    </template>

    <v-card>
      <div class="user-menu">
        <div class="user-menu__title">
          <UserAvatar />

          <span class="user-menu__full-name" :title="userInfo.fullName">{{
            userInfo.fullName
          }}</span>
        </div>

        <v-btn
          class="profile-menu__action-button mb-1"
          depressed
          plain
          x-large
          @click="handleClickProfile"
        >
          <v-icon class="mr-3" color="#B4B4B4">mdi-account-outline</v-icon>
          <span>{{ $t("profile.profile_and_settings") }}</span>
        </v-btn>
        <v-btn
          v-if="this.$store.getters.user.partner_type_code !== null"
          class="profile-menu__action-button mb-4"
          depressed
          plain
          x-large
          :loading="isLoading"
          @click="handleMyListingsClick"
        >
          <v-icon class="mr-3" color="#B4B4B4">mdi-home-edit-outline</v-icon>
          <span>{{ $t("profile.my_listings") }}</span>
        </v-btn>

        <div class="profile-menu__actions">
          <v-btn
            class="profile-menu__action-button"
            depressed
            plain
            x-large
            @click="handleContactUsClick"
          >
            <v-icon class="mr-3" color="#B4B4B4"
              >mdi-message-text-outline</v-icon
            >
            <span>{{ $t("map.contact_us") }}</span>
          </v-btn>

          <v-btn
            class="profile-menu__action-button"
            depressed
            plain
            x-large
            href="https://t.me/turnintolocal"
            target="_blank"
          >
            <v-icon class="mr-3" color="#B4B4B4">$telegramMessage</v-icon>
            <span>{{ $t("map.telegram_channel") }}</span>
          </v-btn>
        </div>

        <v-divider class="my-4"></v-divider>

        <v-btn
          depressed
          plain
          class="profile-menu__action-button user-menu__logout-button"
          @click="handleClickLogOut"
        >
          <v-icon class="mr-3" color="#B4B4B4">mdi-logout</v-icon>
          <span>{{ $t("profile.logout") }}</span>
        </v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import i18n from "@/i18n";
import { api } from "@/api";
import router from "@/router";
import CryptoJS from "crypto-js";
import { getAccess } from "@/services/tokenService";
import UserAvatar from "@/components/User/UserAvatar.vue";

export default {
  name: "UserMenu",
  components: { UserAvatar },
  data() {
    return {
      isUserMenuOpen: false,
      isLoading: false,
    };
  },
  methods: {
    async handleClickLogOut() {
      await this.$store.dispatch("setAuthentication", false);
      await this.$store.dispatch("setUser", null);
      await this.$store.dispatch("setTokens", null);
      await getAccess();
      await router.push({ name: "map", params: { lang: i18n.locale } });
    },
    handleCloseUserMenuClick() {
      this.isUserMenuOpen = false;
    },
    handleContactUsClick() {
      this.handleCloseUserMenuClick();
      this.$emit("handleLegalClicked", {
        tr: "contact_us",
      });
    },
    handleClickProfile() {
      this.handleCloseUserMenuClick();
      router.push({ name: "profile", params: { lang: i18n.locale } });
    },
    encryptToken(token) {
      const encryptionKey = process.env.VUE_APP_ENCRYPTION_KEY;

      return CryptoJS.AES.encrypt(token, encryptionKey).toString();
    },
    openPartnerPortal(url) {
      window.open(url, "_blank")?.focus;
    },
    async handleMyListingsClick() {
      const encryptedToken = this.encryptToken(
        this.$store.getters.tokens.refresh_token
      );
      const encodedToken = encodeURIComponent(encryptedToken);

      this.isLoading = true;
      await api.post("/partners/register_or_get/");
      this.isLoading = false;

      const url = new URL(`${this.partnerPortalUrl}?auth=${encodedToken}`);
      this.openPartnerPortal(url);
    },
  },
  computed: {
    partnerPortalUrl() {
      return `${process.env.VUE_APP_PARTNER_PORTAL_URL}/${this.$i18n.locale}`;
    },
    userInfo() {
      return !this.$store.getters.isAuthenticated
        ? null
        : {
            fullName: `${this.$store.getters.user.first_name} ${this.$store.getters.user.last_name}`,
            initials: `${this.$store.getters.user.first_name[0] || ""}${
              this.$store.getters.user.last_name[0] || ""
            }`,
            profilePic:
              !this.$store.getters.isAuthenticated ||
              (this.$store.getters.user.profile.picture_url &&
                this.$store.getters.user.profile.picture_url.length === 0)
                ? null
                : this.$store.getters.user.profile.picture_url,
          };
    },
  },
  mounted() {
    window.addEventListener("wheel", this.handleCloseUserMenuClick);
    window.addEventListener("touchmove", this.handleCloseUserMenuClick);
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleCloseUserMenuClick);
    window.removeEventListener("touchmove", this.handleCloseUserMenuClick);
  },
};
</script>

<style lang="scss">
.user-menu-wrapper {
  max-width: 100% !important;
}
.user-menu {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 24px;
  padding-bottom: 16px;
  width: 320px;

  @media (max-width: 425px) {
    padding: 16px;
    width: 280px;
  }

  .user-menu__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__full-name {
    color: #3d3d3d;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  &__close-button {
    padding-inline: 0 !important;
    min-width: 30px !important;
  }

  &__enter-button {
    margin-top: 24px;
    border-radius: 8px !important;
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-transform: initial;
    letter-spacing: 0;
  }

  &__section {
    padding: 16px 0;
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__action-button {
    justify-content: flex-start !important;
    text-transform: initial !important;
    color: #3d3d3d !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    padding-inline: 8px !important;
    margin-inline: -8px !important;

    &:hover {
      .v-icon {
        color: #fbad00 !important;
      }
    }

    .v-btn__content {
      opacity: 1 !important;
    }
  }

  .user-menu__logout-button {
    text-transform: initial !important;
    color: #3d3d3d !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
  }
}
</style>
