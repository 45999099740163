<template>
  <v-dialog v-model="isOpenProp" persistent max-width="290">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <AgreementText />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="handleClickLogOut">
          {{ $t("profile.privacy_warning_cancel") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          color="green darken-1"
          text
          @click="handleOkCLick"
        >
          {{ $t("profile.privacy_warning_ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "@/main";
import { getAccess } from "@/services/tokenService";
import AgreementText from "@/components/Auth/AgreementText.vue";
import { LINKS } from "@/components/Common/constants";
import { navigate } from "@/components/Common/helpers/utils";
import { api } from "@/api";
export default {
  name: "AgreementDialog",
  props: {
    isOpen: Boolean,
  },
  components: {
    AgreementText,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isOpenProp() {
      return this.isOpen;
    },
    links() {
      return LINKS;
    },
  },
  methods: {
    handleLegalClicked(link) {
      eventBus.$emit("legalClick", link);
    },
    async handleClickLogOut() {
      await this.$store.dispatch("setAuthentication", false);
      await this.$store.dispatch("setUser", null);
      await this.$store.dispatch("setTokens", null);
      await getAccess();
      navigate(this);
    },
    async handleOkCLick() {
      eventBus.$emit("showSnackbar", {
        text: this.$t("profile.login_success"),
        color: "success",
        timeout: 3000,
      });
      this.isLoading = true;

      const { data } = await api.post(
        "/auth/user/update/",
        {
          personalDataAgreement: 1,
        },
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      );

      this.$store.dispatch("setUser", data);
      navigate(this);
      this.isLoading = false;
    },
  },
};
</script>
<style>
.v-dialog__content {
  z-index: 99999 !important;
}
.v-overlay {
  z-index: 99998 !important;
}
</style>
