<template>
  <v-card-text>
    <body class="c14 doc-content">
      <p class="c7"><span class="c16">COOKIE POLICY</span></p>
      <p class="c5"><span class="c1"></span></p>
      <p class="c15">
        <span class="c8">Last updated </span
        ><span class="c8">Apr 21, 2023</span>
      </p>
      <p class="c5"><span class="c1"></span></p>
      <p class="c5"><span class="c1"></span></p>
      <p class="c2">
        <span class="c8"
          >This Cookie Policy explains how TEOXOFT.COM LLC (&quot;Company&quot;,
          &quot;we&quot;, &quot;us&quot;, and &quot;our&quot;) uses cookies and
          similar technologies to recognize you when you visit our websites at </span
        ><span class="c10"
          ><a
            class="c9"
            :href="`https://www.google.com/url?q=https://${domain}&amp;sa=D&amp;source=editors&amp;ust=1682372412115624&amp;usg=AOvVaw0cBD2hF5aS8QnoQKN8XI21`"
            >https://{{ domain }}</a
          ></span
        ><span class="c4"
          >&nbsp; (&quot;Websites&quot;). It explains what these technologies
          are and why we use them, as well as your rights to control our use of
          them.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >In some cases we may use cookies to collect personal information, or
          that becomes personal information if we combine it with other
          information.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2"><span class="c3">What are cookies?</span></p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >Cookies are small data files that are placed on your computer or
          mobile device when you visit a website. Cookies are widely used by
          website owners in order to make their websites work, or to work more
          efficiently, as well as to provide reporting information.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >Cookies set by the website owner (in this case, TEOXOFT.COM LLC) are
          called &quot;first party cookies&quot;. Cookies set by parties other
          than the website owner are called &quot;third party cookies&quot;.
          Third party cookies enable third party features or functionality to be
          provided on or through the website (e.g. like advertising, interactive
          content and analytics). The parties that set these third party cookies
          can recognize your computer both when it visits the website in
          question and also when it visits certain other websites.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2"><span class="c3">Why do we use cookies?</span></p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >We use first and third party cookies for several reasons. Some
          cookies are required for technical reasons in order for our Websites
          to operate, and we refer to these as &quot;essential&quot; or
          &quot;strictly necessary&quot; cookies. Other cookies also enable us
          to track and target the interests of our users to enhance the
          experience on our Online Properties. Third parties serve cookies
          through our Websites for advertising, analytics and other purposes.
          This is described in more detail below.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >The specific types of first and third party cookies served through
          our Websites and the purposes they perform are described below (please
          note that the specific cookies served may vary depending on the
          specific Online Properties you visit):</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2"><span class="c3">How can I control cookies?</span></p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >You have the right to decide whether to accept or reject cookies. You
          can exercise your cookie rights by setting your preferences in the
          Cookie Consent Manager. The Cookie Consent Manager allows you to
          select which categories of cookies you accept or reject. Essential
          cookies cannot be rejected as they are strictly necessary to provide
          you with services.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >The Cookie Consent Manager can be found in the notification banner
          and on our website. If you choose to reject cookies, you may still use
          our website though your access to some functionality and areas of our
          website may be restricted. You may also set or amend your web browser
          controls to accept or refuse cookies. As the means by which you can
          refuse cookies through your web browser controls vary from
          browser-to-browser, you should visit your browser&#39;s help menu for
          more information.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c8"
          >In addition, most advertising networks offer you a way to opt out of
          targeted advertising. If you would like to find out more information,
          please visit </span
        ><span class="c6"
          ><a
            class="c9"
            href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;source=editors&amp;ust=1682372412118161&amp;usg=AOvVaw2Tg1G9ioMpSM9mJOC3k5XM"
            >http://www.aboutads.info/choices/</a
          ></span
        ><span class="c8">&nbsp;or </span
        ><span class="c6"
          ><a
            class="c9"
            href="https://www.google.com/url?q=https://www.youronlinechoices.com/&amp;sa=D&amp;source=editors&amp;ust=1682372412118428&amp;usg=AOvVaw14t023MYqCYneqgCSqzWGh"
            >http://www.youronlinechoices.com</a
          ></span
        ><span class="c4">.</span>
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >The specific types of first and third party cookies served through
          our Websites and the purposes they perform are described in the table
          below (please note that the specific cookies served may vary depending
          on the specific Online Properties you visit):</span
        >
      </p>
      <p class="c0"><span class="c4"></span></p>
      <p class="c2">
        <span class="c3"
          >What about other tracking technologies, like web beacons?</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >Cookies are not the only way to recognize or track visitors to a
          website. We may use other, similar technologies from time to time,
          like web beacons (sometimes called &quot;tracking pixels&quot; or
          &quot;clear gifs&quot;). These are tiny graphics files that contain a
          unique identifier that enable us to recognize when someone has visited
          our Websites or opened an e-mail including them. This allows us, for
          example, to monitor the traffic patterns of users from one page within
          a website to another, to deliver or communicate with cookies, to
          understand whether you have come to the website from an online
          advertisement displayed on a third-party website, to improve site
          performance, and to measure the success of e-mail marketing campaigns.
          In many instances, these technologies are reliant on cookies to
          function properly, and so declining cookies will impair their
          functioning.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c3"
          >Do you use Flash cookies or Local Shared Objects?</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >Websites may also use so-called &quot;Flash Cookies&quot; (also known
          as Local Shared Objects or &quot;LSOs&quot;) to, among other things,
          collect and store information about your use of our services, fraud
          prevention and for other site operations.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c8"
          >If you do not want Flash Cookies stored on your computer, you can
          adjust the settings of your Flash player to block Flash Cookies
          storage using the tools contained in the </span
        ><span class="c13"
          ><a
            class="c9"
            href="https://www.google.com/url?q=http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html&amp;sa=D&amp;source=editors&amp;ust=1682372412119553&amp;usg=AOvVaw19HAMITO4FZQThVCWPQPXd"
            >Website Storage Settings Panel</a
          ></span
        ><span class="c17"
          >. You can also control Flash Cookies by going to the </span
        ><span class="c13"
          ><a
            class="c9"
            href="https://www.google.com/url?q=http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html&amp;sa=D&amp;source=editors&amp;ust=1682372412119756&amp;usg=AOvVaw3lJ4zTOj9OehjWLNyID0L0"
            >Global Storage Settings Panel</a
          ></span
        ><span class="c4"
          >&nbsp;and following the instructions (which may include instructions
          that explain, for example, how to delete existing Flash Cookies
          (referred to &quot;information&quot; on the Macromedia site), how to
          prevent Flash LSOs from being placed on your computer without your
          being asked, and (for Flash Player 8 and later) how to block Flash
          Cookies that are not being delivered by the operator of the page you
          are on at the time).</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >Please note that setting the Flash Player to restrict or limit
          acceptance of Flash Cookies may reduce or impede the functionality of
          some Flash applications, including, potentially, Flash applications
          used in connection with our services or online content.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c3">Do you serve targeted advertising?</span>
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >Third parties may serve cookies on your computer or mobile device to
          serve advertising through our Websites. These companies may use
          information about your visits to this and other websites in order to
          provide relevant advertisements about goods and services that you may
          be interested in. They may also employ technology that is used to
          measure the effectiveness of advertisements. This can be accomplished
          by them using cookies or web beacons to collect information about your
          visits to this and other sites in order to provide relevant
          advertisements about goods and services of potential interest to you.
          The information collected through this process does not enable us or
          them to identify your name, contact details or other details that
          directly identify you unless you choose to provide these.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c3">How often will you update this Cookie Policy?</span>
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >We may update this Cookie Policy from time to time in order to
          reflect, for example, changes to the cookies we use or for other
          operational, legal or regulatory reasons. Please therefore re-visit
          this Cookie Policy regularly to stay informed about our use of cookies
          and related technologies.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c4"
          >The date at the top of this Cookie Policy indicates when it was last
          updated.</span
        >
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c3">Where can I get further information?</span>
      </p>
      <p class="c0"><span class="c1"></span></p>
      <p class="c2">
        <span class="c8"
          >If you have any questions about our use of cookies or other
          technologies, please email us at </span
        ><span class="c8 c11">contact@{{ domain }}</span>
      </p>
      <p class="c15">
        <span class="c8"
          >This cookie policy was created using Termly&#39;s </span
        ><span class="c6"
          ><a
            class="c9"
            href="https://www.google.com/url?q=https://termly.io/products/cookie-consent-manager/&amp;sa=D&amp;source=editors&amp;ust=1682372412121249&amp;usg=AOvVaw3zbq_d4bVr8WkDHNEF5-uU"
            >Cookie Consent Manager</a
          ></span
        ><span class="c4">.</span>
      </p>
      <p class="c12"><span class="c18"></span></p>
    </body>
  </v-card-text>
</template>

<script>
export default {
  name: "LegalCookie",
  computed: {
    domain() {
      return process.env.VUE_APP_DOMAIN;
    },
  },
};
</script>

<style scoped>
@import url("https://themes.googleusercontent.com/fonts/css?kit=OPeqXG-QxW3ZD8BtmPikfA");
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c0 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c5 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c2 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c18 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c15 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14.5pt;
  font-family: "Arial";
  font-style: normal;
}
.c4 {
  color: #595959;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: "Arial";
  font-style: normal;
}
.c16 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 19.5pt;
  font-family: "Arial";
  font-style: normal;
}
.c7 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c12 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: "Roboto";
  font-style: normal;
}
.c10 {
  text-decoration-skip-ink: none;
  font-size: 10.5pt;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c11 {
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}
.c6 {
  text-decoration-skip-ink: none;
  font-size: 10.5pt;
  -webkit-text-decoration-skip: none;
  color: #3030f1;
  text-decoration: underline;
}
.c13 {
  text-decoration-skip-ink: none;
  font-size: 11.5pt;
  -webkit-text-decoration-skip: none;
  color: #3030f1;
  text-decoration: underline;
}
.c14 {
  background-color: #ffffff;
  padding: 8px;
}
.c17 {
  color: #595959;
  font-size: 11.5pt;
}
.c8 {
  color: #595959;
  font-size: 10.5pt;
}
.c9 {
  color: inherit;
  text-decoration: inherit;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
