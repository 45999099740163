<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 9V9.00005C22 16.3512 15.3285 19.8061 12.884 20.8254C12.3145 21.0628 11.6855 21.0628 11.116 20.8254C9.16666 20.0126 4.52947 17.651 2.73072 13H9.83959L11.0798 15.8939C11.2374 16.2616 11.599 16.5 11.999 16.5C12.399 16.5 12.7605 16.2616 12.9181 15.8939L14.999 11.0386L15.5798 12.3939C15.7374 12.7616 16.099 13 16.499 13H21.2683C21.5099 12.3752 21.7003 11.7092 21.8261 11H17.1584L15.9181 8.10608C15.7605 7.7384 15.399 7.5 14.999 7.5C14.599 7.5 14.2374 7.7384 14.0798 8.10608L11.999 12.9614L11.4181 11.6061C11.2605 11.2384 10.899 11 10.499 11H2.17289C2.06079 10.368 2 9.70181 2 9.00009V9C2 5.68629 4.68629 3 8 3C9.53671 3 10.9385 3.57771 12 4.52779C13.0615 3.57771 14.4633 3 16 3C19.3137 3 22 5.68629 22 9Z"
      fill="#FF4C5B"
    />
  </svg>
</template>

<script>
export default {
  name: "HeartRateIcon",
};
</script>

<style scoped></style>
