<script>
export default {
  name: "HomeCheckedIcon",
  props: {
    fill: {
      type: String,
      default: "#B4B4B4",
    },
  },
};
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10.5256L10.4993 3.49031C11.382 2.83656 12.618 2.83656 13.5007 3.49031L23 10.5256M4.66667 8.19795V18.6724C4.66667 19.9579 5.76108 21 7.11111 21H16.8889C18.2389 21 19.3333 19.9579 19.3333 18.6724V8.19795"
      :stroke="fill"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M8.5 12.5L11 15L15.5 10.5"
      :stroke="fill"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
