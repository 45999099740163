<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons / external link">
      <path
        id="Vector"
        d="M18.1875 13.125H17.625C17.4758 13.125 17.3327 13.1843 17.2273 13.2898C17.1218 13.3952 17.0625 13.5383 17.0625 13.6875V19.1016C17.0625 19.1575 17.0403 19.2112 17.0007 19.2507C16.9612 19.2903 16.9075 19.3125 16.8516 19.3125H4.89844C4.84249 19.3125 4.78884 19.2903 4.74928 19.2507C4.70972 19.2112 4.6875 19.1575 4.6875 19.1016V7.14844C4.6875 7.09249 4.70972 7.03884 4.74928 6.99928C4.78884 6.95972 4.84249 6.9375 4.89844 6.9375H10.3125C10.4617 6.9375 10.6048 6.87824 10.7102 6.77275C10.8157 6.66726 10.875 6.52418 10.875 6.375V5.8125C10.875 5.66332 10.8157 5.52024 10.7102 5.41475C10.6048 5.30926 10.4617 5.25 10.3125 5.25H4.6875C4.23995 5.25 3.81072 5.42779 3.49426 5.74426C3.17779 6.06072 3 6.48995 3 6.9375V19.3125C3 19.7601 3.17779 20.1893 3.49426 20.5057C3.81072 20.8222 4.23995 21 4.6875 21H17.0625C17.5101 21 17.9393 20.8222 18.2557 20.5057C18.5722 20.1893 18.75 19.7601 18.75 19.3125V13.6875C18.75 13.5383 18.6907 13.3952 18.5852 13.2898C18.4798 13.1843 18.3367 13.125 18.1875 13.125ZM20.5781 3H15.7969C15.7135 3.00007 15.632 3.02486 15.5627 3.07122C15.4934 3.11758 15.4394 3.18344 15.4075 3.26048C15.3756 3.33752 15.3672 3.42229 15.3835 3.50408C15.3997 3.58586 15.4398 3.661 15.4987 3.72L17.1929 5.41488L7.6234 14.9844C7.58407 15.0236 7.55286 15.0702 7.53157 15.1215C7.51028 15.1727 7.49932 15.2277 7.49932 15.2832C7.49932 15.3388 7.51028 15.3937 7.53157 15.445C7.55286 15.4963 7.58407 15.5429 7.6234 15.5821L8.41898 16.3777C8.45818 16.417 8.50475 16.4482 8.55603 16.4695C8.60731 16.4908 8.66229 16.5017 8.71781 16.5017C8.77334 16.5017 8.82832 16.4908 8.8796 16.4695C8.93087 16.4482 8.97745 16.417 9.01664 16.3777L18.5862 6.80777L20.2811 8.5023C20.3402 8.56093 20.4153 8.60076 20.497 8.61676C20.5787 8.63277 20.6633 8.62424 20.7402 8.59226C20.8171 8.56027 20.8828 8.50625 20.929 8.437C20.9752 8.36776 20.9999 8.28638 21 8.20312V3.42188C21 3.30999 20.9556 3.20268 20.8764 3.12356C20.7973 3.04445 20.69 3 20.5781 3V3Z"
        fill="#B4B4B4"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "LinkIcon",
};
</script>

<style scoped></style>
