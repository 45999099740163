<template>
  <svg
    width="590"
    height="588"
    viewBox="0 0 590 588"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M338.278 472.564C333.564 468.441 326.495 469.453 322.425 474.772C313.299 486.725 302.255 500.532 289.574 512.349C284.74 516.853 283.992 524.535 287.882 529.51C289.231 531.232 290.954 532.395 292.834 533.007C296.399 534.165 300.524 533.316 303.687 530.37C317.751 517.253 329.646 502.402 339.425 489.604C343.481 484.292 342.966 476.666 338.278 472.564Z"
      fill="#0000008a"
    />
    <path
      d="M243.3 542.717C229.37 546.736 215.014 547.893 199.388 546.258C193.27 545.617 187.65 550.542 186.833 557.256C186.182 562.649 188.829 567.561 193.078 569.611C194.111 570.11 195.239 570.442 196.432 570.569C214.478 572.461 231.902 571.028 248.234 566.317C254.253 564.571 258.029 557.885 256.668 551.375C255.27 544.862 249.318 540.945 243.3 542.717Z"
      fill="#0000008a"
    />
    <path
      d="M405.241 382.417C385.464 390.198 372.561 407.847 364.569 420.434C361.074 425.93 362.348 433.58 367.415 437.511C368.835 438.62 370.403 439.31 371.992 439.617C376.095 440.41 380.39 438.651 382.901 434.696C393.047 418.715 402.357 409.71 413.101 405.471C418.848 403.206 421.763 396.208 419.587 389.835C417.434 383.467 411.033 380.137 405.241 382.417Z"
      fill="#0000008a"
    />
    <path
      d="M481.687 289.632C475.587 290.374 471.498 296.358 472.624 302.991C473.835 310.039 473.717 321.469 458.748 339.148C454.714 343.922 455.295 351.566 460.069 356.23C464.572 360.631 471.19 360.788 475.313 356.769C475.557 356.532 475.793 356.285 476.013 356.02C491.912 337.237 498.078 319.003 494.868 300.274C493.735 293.649 487.827 288.884 481.687 289.632Z"
      fill="#0000008a"
    />
    <path
      d="M480 25.8095C510.25 25.8095 535 49.0381 535 77.4286C535 104.529 506.125 148.405 480 179.376C453.875 147.114 425 104.529 425 77.4286C425 49.0381 449.75 25.8095 480 25.8095ZM480 0C434.625 0 397.5 34.8429 397.5 77.4286C397.5 135.5 480 219.381 480 219.381C480 219.381 562.5 134.21 562.5 77.4286C562.5 34.8429 525.375 0 480 0ZM480 51.6191C464.875 51.6191 452.5 63.2333 452.5 77.4286C452.5 91.6238 464.875 103.238 480 103.238C495.125 103.238 507.5 91.6238 507.5 77.4286C507.5 63.2333 495.125 51.6191 480 51.6191ZM590 219.381C590 247.771 540.5 271 480 271C419.5 271 370 247.771 370 219.381C370 202.605 386.5 188.41 412.625 178.086L420.875 189.7C407.125 196.152 397.5 203.895 397.5 212.929C397.5 230.995 434.625 245.19 480 245.19C525.375 245.19 562.5 230.995 562.5 212.929C562.5 203.895 552.875 196.152 537.75 189.7L546 178.086C573.5 188.41 590 202.605 590 219.381Z"
      fill="#0000008a"
    />
    <path
      d="M151.752 186.047C205.1 186.047 248.748 229.458 248.748 282.516C248.748 333.162 197.825 415.16 151.752 473.041C105.679 412.748 54.7559 333.162 54.7559 282.516C54.7559 229.458 98.4041 186.047 151.752 186.047ZM151.752 137.812C71.7302 137.812 6.25781 202.929 6.25781 282.516C6.25781 391.043 151.752 547.805 151.752 547.805C151.752 547.805 297.246 388.631 297.246 282.516C297.246 202.929 231.774 137.812 151.752 137.812ZM151.752 234.281C125.078 234.281 103.254 255.987 103.254 282.516C103.254 309.045 125.078 330.75 151.752 330.75C178.426 330.75 200.25 309.045 200.25 282.516C200.25 255.987 178.426 234.281 151.752 234.281Z"
      fill="#0000008a"
    />
  </svg>
</template>

<script>
export default {
  name: "MarkerDistanceIcon",
};
</script>

<style scoped></style>
