<template>
  <v-banner class="cookie-banner_wrapper" single-line width="100%" dark>
    {{ $t("cookie_warning") }}

    <template v-slot:actions>
      <v-btn depressed color="#fecc00" light @click="handleOkClick" small
        >Ok</v-btn
      >
      <v-btn depressed @click="handleLearnMoreClick" small>{{
        $t("learn_more")
      }}</v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  name: "CookieBanner",
  methods: {
    handleLearnMoreClick() {
      this.$emit("learnMoreClicked");
    },
    handleOkClick() {
      this.$emit("okClicked");
    },
  },
};
</script>
<style lang="scss">
.cookie-banner_wrapper {
  position: absolute !important;
  top: 0% !important;
  transform: translateY(-100%);
  padding: 0 !important;
  z-index: 21 !important;
  background: #3d3d3d !important;

  .v-banner__wrapper {
    flex-wrap: wrap !important;
    gap: 8px !important;

    @media (max-width: 1000px) {
      justify-content: center !important;
    }

    .v-banner__text {
      white-space: initial !important;

      @media (max-width: 1000px) {
        text-align: center !important;
        font-size: 12px !important;
        line-height: 14px !important;
      }
    }

    .v-banner__actions {
      margin-left: 0 !important;
    }
  }
}
</style>
