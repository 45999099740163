<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 19.5V4.5H10V10.5H14.5V4.5H18V19.5H14.5V14H10V19.5H6Z"
      fill="#FF4C5B"
    />
  </svg>
</template>

<script>
export default {
  name: "HospitalIcon",
};
</script>

<style scoped></style>
