<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons / bookmark">
      <path
        id="Vector"
        d="M16.5 4H7.5C6.67156 4 6 4.67156 6 5.5V20L12 15.7366L18 20V5.5C18 4.67156 17.3284 4 16.5 4ZM16.5 17.3884L12 14L7.5 17.3884V5.6875C7.5 5.63777 7.51975 5.59008 7.55492 5.55492C7.59008 5.51975 7.63777 5.5 7.6875 5.5H16.3125C16.4161 5.5 16.5 5.58384 16.5 5.68737V17.3884Z"
        :fill="fill"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      default: "#B4B4B4",
      type: String,
    },
  },
  name: "BookmarkIcon",
};
</script>

<style scoped></style>
