import { UTM_PARAMS, HTTP_REFERER } from "@/components/Common/constants";

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export function formatNumberToWords(number, lang) {
  const units =
    lang === "ru"
      ? ["", "тыс.", "млн.", "млрд.", "трлн."]
      : ["", "K", "M", "B", "T"];
  const base = 1000;

  if (number < base) {
    return number.toString();
  }

  const exponent = Math.floor(Math.log10(number) / 3);
  const unit = units[exponent];

  const formattedNumber =
    exponent === 1
      ? (number / Math.pow(base, exponent)).toFixed(0)
      : (number / Math.pow(base, exponent)).toFixed(3);

  return `${formattedNumber} ${unit}`;
}

export const formatPrice = (price) => {
  let formattedPrice = [];
  price
    .toString()
    .split("")
    .reverse()
    .forEach((n, i) => {
      if ((i + 1) % 3 === 0) {
        formattedPrice.push(n);
        formattedPrice.push(" ");
      } else formattedPrice.push(n);
    });
  formattedPrice = `${formattedPrice.reverse().join("")}`;
  return formattedPrice;
};

const getRoute = (component) => {
  let queryParamName = Object.keys(component.$route.query)[0];
  let routeQuery = { [queryParamName]: component.$route.query[queryParamName] };
  const routeParams = { lang: component.$i18n.locale };
  const routeMap = {
    "calc-data": "calc",
    "insurance-companies": "insuranceCompanies",
    "health-data": "map",
    hospital: "map",
    property: "map",
  };
  let routeName = "";
  if (queryParamName in routeMap) {
    routeName = routeMap[queryParamName];
    const paramsToSetSearchQueryData = ["health-data"];
    if (paramsToSetSearchQueryData.includes(queryParamName)) {
      component.$store.dispatch(
        "setSearchQueryData",
        JSON.parse(decodeURI(routeQuery[queryParamName]))
      );
    }
    if (routeName === "insuranceCompanies") {
      const slug = routeQuery[queryParamName];
      routeParams["slug"] = slug;
      routeQuery = null;
    }
  } else {
    if (component.$store.getters.isAuthenticated) {
      routeName = "profile";
      routeQuery = null;
    } else return null;
  }
  return {
    name: routeName,
    params: routeParams,
    query: routeQuery,
  };
};

export const navigate = (component) => {
  const route = getRoute(component);
  if (route) {
    component.$router.push(route);
  }
};

const storeActionMap = {
  isAuthenticated: "setAuthentication",
  user: "setUser",
  tokens: "setTokens",
  ilceData: "setIlceData",
  mahalleData: "setMahalleData",
  searchQueryData: "setSearchQueryData",
};

export function extractFromLocalStorageToStore(store, itemName) {
  const data = JSON.parse(localStorage.getItem(itemName));
  localStorage.removeItem(itemName);

  const actionName = storeActionMap[itemName];
  if (data && actionName) {
    store.dispatch(actionName, data);
  }
}

export function getMapCoordinates(il, ilce, mahalle) {
  let mapCoordinates = null;
  if (mahalle) {
    mapCoordinates = {
      zoomLevel: 12,
      lat: mahalle.lat,
      lng: mahalle.lng,
    };
  } else if (ilce) {
    mapCoordinates = {
      zoomLevel: 10.5,
      lat: ilce.lat,
      lng: ilce.lng,
    };
  } else if (il) {
    mapCoordinates = {
      zoomLevel: 9,
      lat: il.lat,
      lng: il.lng,
    };
  }
  return mapCoordinates;
}

/**
 * Generates a Google Maps URL based on a place ID or coordinates.
 *
 * @param {string} placeId - The Google Place ID.
 * @param {number} latitude - The latitude coordinate.
 * @param {number} longitude - The longitude coordinate.
 * @param {number} zoom - The zoom level for the map.
 * @returns {string} The URL for Google Maps.
 */
export function getGoogleMapsURL(placeId, latitude, longitude, zoom) {
  const BASE_URL = "https://www.google.com/maps/search/";
  return placeId
    ? `${BASE_URL}?api=1&query=Google&query_place_id=${placeId}`
    : `${BASE_URL}?api=1&query=${latitude},${longitude}&zoom=${zoom}`;
}

/**
 * Filters out properties from an object that have values of `undefined` or `null`.
 *
 * @param {Object} obj - The object to be filtered.
 * @returns {Object} A new object with all properties that have `undefined` or `null` values removed.
 *
 * @example
 * const input = { a: 1, b: undefined, c: null, d: 4 };
 * const output = filterUndefinedOrNull(input);
 * // output is { a: 1, d: 4 }
 */
export function filterUndefinedOrNull(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) => value !== undefined && value !== null
    )
  );
}

/**
 * Generates an array of year options for a dropdown menu.
 *
 * @param {number} length - The number of years to generate options for, starting from the current year and going backwards.
 * @returns {Array} An array of objects, each containing a `label` and `value` property representing a year.
 *
 * @example
 * const options = generateYearOptions(5);
 * // options is [
 * //   { label: 2024, value: 2024 },
 * //   { label: 2023, value: 2023 },
 * //   { label: 2022, value: 2022 },
 * // ]
 */
export function generateYearOptions(length) {
  const currentYear = new Date().getFullYear();
  return Array.from({ length }, (_, i) => ({
    label: currentYear - i,
    value: currentYear - i,
  }));
}

/**
 * Get the current date in "YYYY-MM-DD" format.
 * @returns {string} The current date in "YYYY-MM-DD" format.
 */
export function getCurrentDate() {
  return new Date().toISOString().substring(0, 10);
}

/**
 * Add days to a given date.
 * @param {string} dateStr The input date string in "YYYY-MM-DD" format.
 * @param {number} daysToAdd Number of days to add.
 * @returns {string} The resulting date in "YYYY-MM-DD" format.
 */
export function addDaysToDate(dateStr, daysToAdd) {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + daysToAdd);
  return date.toISOString().substring(0, 10);
}

const extractUTMparamsFromLocalStorage = () => {
  const utmParams = JSON.parse(localStorage.getItem(UTM_PARAMS)) || {};
  localStorage.removeItem(UTM_PARAMS);
  return utmParams;
};

const extractHTTPrefererFromLocalStorage = () => {
  const HTTPreferer = localStorage.getItem(HTTP_REFERER) || "";
  localStorage.removeItem(HTTP_REFERER);
  return HTTPreferer;
};

/**
 * Adds UTM parameters to a request body object.
 * @param {object} requestBody - The request body object to modify with UTM parameters.
 */
export function addUTMparamsToRequestBody(requestBody) {
  const utmParams = extractUTMparamsFromLocalStorage();
  for (const [key, value] of Object.entries(utmParams)) {
    requestBody[key] = value;
  }
}

/**
 * Adds a HTTP referer to a request body object.
 * @param {object} requestBody - The request body object to modify with referer.
 */
export function addHTTPrefererToRequestBody(requestBody) {
  const HTTPreferer = extractHTTPrefererFromLocalStorage();
  if (HTTPreferer) {
    requestBody["referer"] = HTTPreferer;
  }
}

/**
 * Adds UTM parameters to a URL query string.
 * @param {URL} url - The URL to modify with UTM parameters.
 */
export function addUTMparamsToURL(url) {
  const utmParams = extractUTMparamsFromLocalStorage();
  for (const [key, value] of Object.entries(utmParams)) {
    url.searchParams.append(key, value);
  }
}

/**
 * Adds HTTP referer to a URL query string.
 * @param {URL} url - The URL to modify with referer.
 */
export function addHTTPrefererToURL(url) {
  const HTTPreferer = extractHTTPrefererFromLocalStorage();
  if (HTTPreferer) {
    url.searchParams.append("referer", HTTPreferer);
  }
}
